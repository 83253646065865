import API, { createMAX } from '@zillow/mortgage-api-client';
import { getFullNameFromAbbr } from '@zillow/state-region-id-map';
import { standardRate, DEFAULT_PARTNER_ID, LOAN_AMOUNT_RATE_BUCKET, RATE_TYPE, LOAN_TO_VALUE_RATE_BUCKET } from './constants.js';
export { DEFAULT_PARTNER_ID } from './constants.js';

var _zconfigs = {
  prod: {
    serverURLs: {
      MAX: 'https://mortgageapi.zillow.com'
    },
    partnerId: 'RD-WXDQSGN'
  },
  default: {
    serverURLs: {
      MAX: 'https://mortgageapi.qa.zillow.net'
    },
    partnerId: 'RD-GKZRBBL'
  }
};
var setServerUrl = function setServerUrl() {
  var _zconfig = _zconfigs["prod"] || _zconfigs.default;
  if (!window._zconfig) {
    window._zconfig = _zconfig;
  } else {
    var zconfig = window._zconfig;
    if (!zconfig.partnerId) {
      global.window._zconfig.partnerId = _zconfig.partnerId;
    }
    if (!zconfig.serverURLs) {
      zconfig.serverURLs = {
        MAX: _zconfig.serverURLs.MAX
      };
    } else if (!zconfig.serverURLs.MAX) {
      zconfig.serverURLs.MAX = _zconfig.serverURLs.MAX;
    }
  }
};
var formatCityFromLink = function formatCityFromLink(city) {
  return city && typeof city === 'string' && capitalize(city.replace(/-/g, ' '));
};
var capitalize = function capitalize(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};
var createMax = function createMax() {
  if (!API.MAX) {
    if (!window._zconfig.serverURLs.MAX) {
      throw new Error('MAX serverURL is not set');
    }
    createMAX(window._zconfig.serverURLs.MAX);
  }
  return;
};
var stateAbbreviationValidator = function stateAbbreviationValidator(value) {
  return getFullNameFromAbbr(value) !== undefined;
};
var getCurrentRates = function getCurrentRates(inputs) {
  return new Promise(function ($return, $error) {
    if (!API || !API.MAX || !API.MAX.GET) {
      return $return(Promise.resolve({
        rates: {
          rateQuery: {
            rate: 0
          }
        }
      }));
    }
    var $Try_1_Catch = function (error) {
      try {
        return $return({
          rates: {
            rateQuery: {
              rate: 0
            }
          }
        });
      } catch ($boundEx) {
        return $error($boundEx);
      }
    };
    try {
      return Promise.resolve(API.MAX.GET({
        action: 'getCurrentRates',
        timeout: 3000,
        input: inputs
      })).then($return, $Try_1_Catch);
    } catch (error) {
      $Try_1_Catch();
    }
  });
};
function getStandardInterestRate(loanProgram) {
  if (loanProgram === void 0) {
    loanProgram = 'Fixed30Year';
  }
  return standardRate[loanProgram];
}
function getRateQuery(_ref) {
  var loanAmountParam = _ref.loanAmount,
    homePrice = _ref.homePrice,
    downPayment = _ref.downPayment,
    _ref$loanProgram = _ref.loanProgram,
    loanProgram = _ref$loanProgram === void 0 ? 'Fixed30Year' : _ref$loanProgram,
    _ref$creditScore = _ref.creditScore,
    creditScore = _ref$creditScore === void 0 ? 'R_720_739' : _ref$creditScore,
    _ref$va = _ref.va,
    va = _ref$va === void 0 ? false : _ref$va,
    _ref$refinance = _ref.refinance,
    refinance = _ref$refinance === void 0 ? false : _ref$refinance,
    _ref$fha = _ref.fha,
    fha = _ref$fha === void 0 ? false : _ref$fha,
    _ref$jumbo = _ref.jumbo,
    jumbo = _ref$jumbo === void 0 ? false : _ref$jumbo;
  var rateBucketResults = calculateRateBuckets({
    homePrice: homePrice,
    downPayment: downPayment,
    loanProgram: loanProgram,
    creditScore: creditScore
  });
  var rateQuery = {
    stateAbbreviation: API.stateAbbreviation,
    program: rateBucketResults.loanProgram
  };
  if (rateBucketResults.loanAmountBucket !== LOAN_AMOUNT_RATE_BUCKET.UNSURE) {
    // The loan amount is not a Jumbo loan in any county, so call with generic enums for better caching
    rateQuery.loanAmountBucket = rateBucketResults.loanAmountBucket;
    rateQuery.loanToValueBucket = rateBucketResults.loanToValueBucket;
  } else {
    if (homePrice) {
      // The loan amount may be a Jumbo loan, depending on county, so let the MAX decide based on exact inputs
      var loanAmount = loanAmountParam || homePrice - downPayment;
      rateQuery.propertyBucket = {
        propertyValue: homePrice,
        loanAmount: loanAmount,
        location: {
          stateAbbreviation: API.stateAbbreviation
        }
      };
    }
  }
  if (va) rateQuery.loanType = 'VA';
  if (rateBucketResults.creditScoreBucket) rateQuery.creditScoreBucket = rateBucketResults.creditScoreBucket;
  if (refinance) rateQuery.refinance = true;
  if (fha) rateQuery.loanType = 'FHA';
  if (jumbo) rateQuery.loanType = 'Jumbo';
  return rateQuery;
}
function getCreditScoreBucket(creditScore) {
  var buckets = {
    R_760_: 'VeryHigh',
    R_740_759: 'VeryHigh',
    R_720_739: 'High',
    R_700_719: 'High',
    R_680_699: 'High',
    R_660_679: 'Low',
    R_640_659: 'Low',
    R_620_639: 'Low',
    R_600_619: 'Low',
    R_560_599: 'Low',
    R_300_559: 'Low'
  };
  return creditScore && buckets[creditScore];
}
function getLoanAmountRateBucket(homePrice, downPayment) {
  if (typeof homePrice === 'undefined' || typeof downPayment === 'undefined') {
    return LOAN_AMOUNT_RATE_BUCKET.UNSURE;
  }
  var remainder = homePrice - downPayment;
  if (remainder < 100000) {
    return LOAN_AMOUNT_RATE_BUCKET.MICRO;
  } else if (remainder >= 100000 && remainder < 200000) {
    return LOAN_AMOUNT_RATE_BUCKET.SMALL_CONFORMING;
  } else if (remainder >= 200000 && remainder < 453100) {
    return LOAN_AMOUNT_RATE_BUCKET.CONFORMING;
  }
  return LOAN_AMOUNT_RATE_BUCKET.UNSURE;
}
function getLoanToValueRateBucket(homePrice, downPayment) {
  if (typeof homePrice === 'undefined' || typeof downPayment === 'undefined') {
    return LOAN_TO_VALUE_RATE_BUCKET.NORMAL;
  }
  var downPercent = downPayment / homePrice * 100;
  if (downPercent < 5) {
    return LOAN_TO_VALUE_RATE_BUCKET.VERY_HIGH;
  } else if (downPercent >= 5 && downPercent < 20) {
    return LOAN_TO_VALUE_RATE_BUCKET.HIGH;
  }
  return LOAN_TO_VALUE_RATE_BUCKET.NORMAL;
}
function calculateRateBuckets(_ref2) {
  var homePrice = _ref2.homePrice,
    downPayment = _ref2.downPayment,
    loanProgram = _ref2.loanProgram,
    creditScore = _ref2.creditScore;
  var loanToValueRateBucket = getLoanToValueRateBucket(homePrice, downPayment);
  var loanAmountRateBucket = getLoanAmountRateBucket(homePrice, downPayment);
  var creditScoreBucket = getCreditScoreBucket(creditScore);
  var result = {
    rateType: RATE_TYPE.STANDARD,
    loanProgram: loanProgram,
    loanAmountBucket: loanAmountRateBucket,
    loanToValueBucket: loanToValueRateBucket,
    creditScoreBucket: creditScoreBucket
  };
  if (result.loanAmountBucket !== LOAN_AMOUNT_RATE_BUCKET.CONFORMING || result.loanToValueBucket !== LOAN_TO_VALUE_RATE_BUCKET.NORMAL || result.creditScoreBucket !== 'VeryHigh') {
    result.rateType = RATE_TYPE.NON_STANDARD;
  }
  if (homePrice - downPayment < 0) {
    result.rateType = RATE_TYPE.INVALID;
  }
  return result;
}
var fetchInterestRate = function fetchInterestRate(rateQueryParams) {
  return new Promise(function ($return, $error) {
    var _API$partnerId, loanProgram, standardRate, response, newRate;
    loanProgram = rateQueryParams.loanProgram;
    standardRate = getStandardInterestRate(loanProgram);
    return Promise.resolve(getCurrentRates({
      partnerId: (_API$partnerId = API.partnerId) !== null && _API$partnerId !== void 0 ? _API$partnerId : DEFAULT_PARTNER_ID,
      queries: {
        rateQuery: getRateQuery(rateQueryParams)
      }
    })).then(function ($await_3) {
      try {
        response = $await_3;
        newRate = response.rates.rateQuery.rate;
        if (newRate === 0) {
          // This happens when the number of quotes the rate is based on is 0, so just
          // use defaults in this case
          return $return(standardRate);
        }
        return $return(newRate);
      } catch ($boundEx) {
        return $error($boundEx);
      }
    }, $error);
  });
};
var fetchRate = function fetchRate(rateQueryParams) {
  return new Promise(function ($return, $error) {
    var rate;
    return Promise.resolve(fetchInterestRate(rateQueryParams)).then(function ($await_4) {
      try {
        rate = $await_4;
        return $return(rate == 0 ? standardRate.Fixed30Year : rate);
      } catch ($boundEx) {
        return $error($boundEx);
      }
    }, $error);
  });
};

export { calculateRateBuckets, createMax, fetchInterestRate, fetchRate, formatCityFromLink, getCurrentRates, getLoanAmountRateBucket, getLoanToValueRateBucket, getRateQuery, getStandardInterestRate, setServerUrl, stateAbbreviationValidator };
