import { slicedToArray as _slicedToArray } from '../../_virtual/_rollupPluginBabelHelpers.js';
import _range from 'lodash/range';
import _isNil from 'lodash/isNil';
import _omitBy from 'lodash/omitBy';
import { useState, useEffect } from 'react';
import querystring from 'querystring';
import { formatDate } from '@zillow/formatters';
import { isWebview } from '@zillow/mortgage-webview-query-params';
import { amortization } from '@zillow/mortgage-calculations';
import PropTypes from 'prop-types';
import { COMMON_AMOUNT_CONFIG, LOAN_PROGRAMS, COMMON_PERCENT_CONFIG } from '../../utils/zhl-calculator.js';
import { useLastValidValues } from '../../utils/form/useLastValidValues.js';

var _TERM_TO_YEAR, _TERM_TO_RATE;
var RFC_2822_MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
var YEAR_OPTIONS_MIN = 1900;
var YEAR_OPTIONS_MAX = 2100;
var VALIDATE_YEAR_OPTIONS = _range(YEAR_OPTIONS_MIN, YEAR_OPTIONS_MAX + 1);
//changed to string as options type is string
var START_YEAR_OPTIONS = _range(YEAR_OPTIONS_MIN, YEAR_OPTIONS_MAX + 1).map(function (i) {
  return {
    value: i.toString(),
    label: i.toString()
  };
});
var LOAN_TERM = {
  Fixed30Year: 'Fixed30Year',
  Fixed15Year: 'Fixed15Year',
  ARM5: 'ARM5'
};
var TERM_TO_YEAR = (_TERM_TO_YEAR = {}, _TERM_TO_YEAR[LOAN_TERM.Fixed30Year] = 30, _TERM_TO_YEAR[LOAN_TERM.Fixed15Year] = 15, _TERM_TO_YEAR[LOAN_TERM.ARM5] = 30, _TERM_TO_YEAR);
var TERM_TO_RATE = (_TERM_TO_RATE = {}, _TERM_TO_RATE[LOAN_TERM.Fixed30Year] = {
  rate: 4.176
}, _TERM_TO_RATE[LOAN_TERM.Fixed15Year] = {
  rate: 3.805
}, _TERM_TO_RATE[LOAN_TERM.ARM5] = {
  rate: 3.905
}, _TERM_TO_RATE);
var DEFAULT_VALUES_SHAPE = {
  amount: PropTypes.number,
  rate: PropTypes.string,
  term: PropTypes.number,
  startMonth: PropTypes.string,
  startYear: PropTypes.number
};
var FieldConfig = {
  amount: {
    name: 'amount',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  term: {
    name: 'term',
    options: [{
      value: LOAN_PROGRAMS.fixed30Year,
      label: '30-year fixed'
    }, {
      value: LOAN_PROGRAMS.fixed15Year,
      label: '15-year fixed'
    }, {
      value: LOAN_PROGRAMS.arm5,
      label: '5-year ARM'
    }],
    config: {
      validate: {
        invalidProgram: function invalidProgram(value) {
          var error = null;
          if (Object.values(LOAN_PROGRAMS).includes(value) === false) {
            error = 'Invalid loan program';
          }
          return error;
        }
      }
    }
  },
  rate: {
    name: 'rate',
    config: Object.assign({}, COMMON_PERCENT_CONFIG)
  },
  startMonth: {
    name: 'startMonth',
    options: RFC_2822_MONTHS.map(function (i) {
      return {
        value: i,
        label: i
      };
    }),
    config: {
      validate: {
        invalidProgram: function invalidProgram(value) {
          var error = null;
          if (RFC_2822_MONTHS.includes(value) === false) {
            error = 'Invalid start month';
          }
          return error;
        }
      }
    }
  },
  startYear: {
    name: 'startYear',
    options: START_YEAR_OPTIONS,
    config: {
      validate: {
        invalidProgram: function invalidProgram(value) {
          var error = null;
          if (VALIDATE_YEAR_OPTIONS.includes(parseInt(value)) === false) {
            error = 'Invalid start year';
          }
          return error;
        }
      }
    }
  }
};
var safe = function safe(fn, fallback) {
  if (fallback === void 0) {
    fallback = null;
  }
  return function (x) {
    return x ? fn(x) : fallback;
  };
};
function useLastValidAmortizationValues(form) {
  return useLastValidValues(form, getAmortizationValues);
}
var getZillowURL = function getZillowURL(path) {
  var zillowOrigin = 'https://www.zillow.com';
  return zillowOrigin + "/" + path.replace(/^\//, '');
};
var getDateFromAmortizationMonth = function getDateFromAmortizationMonth(month, startMonth, startYear) {
  var formatedStartMonth = RFC_2822_MONTHS.indexOf(startMonth) + 1;
  var jsDate = new Date(startYear, formatedStartMonth);
  jsDate.setMonth(month + jsDate.getMonth() - 1);
  return formatDate(jsDate, 'M/Y');
};
function getAmortizationValues(lastValidFields) {
  var _amort, _amort2, _amort3, _amort$, _amort$2;
  var numYears = TERM_TO_YEAR[lastValidFields.term];
  var amort = amortization(lastValidFields.amount, lastValidFields.rate, numYears * 12).map(function (item) {
    return Object.assign({}, item, {
      // table date format
      formattedDate: getDateFromAmortizationMonth(item.month, lastValidFields.startMonth, lastValidFields.startYear)
    });
  });
  var totalInterest = (_amort = amort[amort.length - 1]) === null || _amort === void 0 ? void 0 : _amort.totalInterest;
  var totalPrincipal = (_amort2 = amort[amort.length - 1]) === null || _amort2 === void 0 ? void 0 : _amort2.totalPrincipal;
  var totalPayment = totalInterest + ((_amort3 = amort[amort.length - 1]) === null || _amort3 === void 0 ? void 0 : _amort3.totalPrincipal);
  var monthlyPayment = ((_amort$ = amort[0]) === null || _amort$ === void 0 ? void 0 : _amort$.monthlyInterest) + ((_amort$2 = amort[0]) === null || _amort$2 === void 0 ? void 0 : _amort$2.monthlyPrincipal);
  var calculations = {
    totalInterest: totalInterest,
    totalPrincipal: totalPrincipal,
    totalPayment: totalPayment,
    amortization: amort,
    monthlyPayment: monthlyPayment
  };
  var values = {
    fields: lastValidFields,
    calculations: calculations
  };
  return values;
}
function useCustomQuotesURL(pathBase, params) {
  if (pathBase === void 0) {
    pathBase = 'mortgage-rates';
  }
  if (params === void 0) {
    params = {};
  }
  var _useState = useState(pathBase + '/'),
    _useState2 = _slicedToArray(_useState, 2),
    URI = _useState2[0],
    setURI = _useState2[1];
  useEffect(function () {
    var path = isWebview() ? 'mortgage/webviews/ZCQ.htm' : pathBase + '/';
    var queryString = querystring.stringify(_omitBy(params, _isNil));
    if (queryString) {
      queryString = "?" + queryString;
    }
    setURI(path + queryString);
  }, [pathBase, params]);
  return getZillowURL(URI);
}

export { DEFAULT_VALUES_SHAPE, FieldConfig, LOAN_TERM, RFC_2822_MONTHS, START_YEAR_OPTIONS, TERM_TO_RATE, TERM_TO_YEAR, VALIDATE_YEAR_OPTIONS, getAmortizationValues, getZillowURL, safe, useCustomQuotesURL, useLastValidAmortizationValues };
