import { amortization, monthlyPI } from '@zillow/mortgage-calculations';
import { LOAN_PROGRAMS, COMMON_AMOUNT_CONFIG, COMMON_PERCENT_CONFIG, LOAN_TERM_IN_MONTHS } from '../../utils/zhl-calculator.js';
import { RFC_2822_MONTHS, START_YEAR_OPTIONS, VALIDATE_YEAR_OPTIONS, TERM_TO_YEAR } from '../AmortizationCalculator/AmortizationCalculator.utils.js';
import { useLastValidValues } from '../../utils/form/useLastValidValues.js';

var loanTermOptions = [{
  value: LOAN_PROGRAMS.fixed30Year,
  label: '30-year fixed'
}, {
  value: LOAN_PROGRAMS.fixed15Year,
  label: '15-year fixed'
}, {
  value: LOAN_PROGRAMS.arm5,
  label: '5-year ARM'
}];
var loanTermConfig = {
  required: 'Required',
  validate: {
    invalidProgram: function invalidProgram(value) {
      var error = null;
      if (Object.values(LOAN_PROGRAMS).includes(value) === false) {
        error = 'Invalid loan program';
      }
      return error;
    }
  }
};
var loanStartYearConfig = {
  required: 'Required',
  validate: {
    invalidProgram: function invalidProgram(value) {
      var error = null;
      if (VALIDATE_YEAR_OPTIONS.includes(parseInt(value)) === false) {
        error = 'Invalid start year';
      }
      return error;
    }
  }
};
var loanStartMonthConfig = {
  required: 'Required',
  validate: {
    invalidProgram: function invalidProgram(value) {
      var error = null;
      if (RFC_2822_MONTHS.includes(value) === false) {
        error = 'Invalid start month';
      }
      return error;
    }
  }
};
var FieldConfig = {
  typeOneLoanAmount: {
    name: 'typeOneLoanAmount',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  typeOneLoanTerm: {
    name: 'typeOneLoanTerm',
    options: loanTermOptions,
    config: loanTermConfig
  },
  typeOneInterestRate: {
    name: 'typeOneInterestRate',
    config: Object.assign({}, COMMON_PERCENT_CONFIG)
  },
  typeOneLoanStartMonth: {
    name: 'typeOneLoanStartMonth',
    options: RFC_2822_MONTHS.map(function (i) {
      return {
        value: i,
        label: i
      };
    }),
    config: loanStartMonthConfig
  },
  typeOneLoanStartYear: {
    name: 'typeOneLoanStartYear',
    options: START_YEAR_OPTIONS,
    config: loanStartYearConfig
  },
  typeTwoLoanAmount: {
    name: 'typeTwoLoanAmount',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  typeTwoLoanTerm: {
    name: 'typeTwoLoanTerm',
    options: loanTermOptions,
    config: loanTermConfig
  },
  typeTwoInterestRate: {
    name: 'typeTwoInterestRate',
    config: Object.assign({}, COMMON_PERCENT_CONFIG)
  },
  typeTwoLoanStartMonth: {
    name: 'typeTwoLoanStartMonth',
    options: RFC_2822_MONTHS.map(function (i) {
      return {
        value: i,
        label: i
      };
    }),
    config: loanStartMonthConfig
  },
  typeTwoLoanStartYear: {
    name: 'typeTwoLoanStartYear',
    options: START_YEAR_OPTIONS,
    config: loanStartYearConfig
  },
  typeThreeLoanAmount: {
    name: 'typeThreeLoanAmount',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  typeThreeLoanTerm: {
    name: 'typeThreeLoanTerm',
    options: loanTermOptions,
    config: loanTermConfig
  },
  typeThreeInterestRate: {
    name: 'typeThreeInterestRate',
    config: Object.assign({}, COMMON_PERCENT_CONFIG)
  },
  typeThreeLoanStartMonth: {
    name: 'typeThreeLoanStartMonth',
    options: RFC_2822_MONTHS.map(function (i) {
      return {
        value: i,
        label: i
      };
    }),
    config: loanStartMonthConfig
  },
  typeThreeLoanStartYear: {
    name: 'typeThreeLoanStartYear',
    options: START_YEAR_OPTIONS,
    config: loanStartYearConfig
  }
};
function useLastValidFormValues(form) {
  return useLastValidValues(form, getAmortizationValues);
}
function getAmortizationFieldValues(inputs) {
  var numYears = TERM_TO_YEAR[inputs.term];
  var payOffYear = +numYears + +inputs.loanStartYear;
  var amort = amortization(inputs.amount, inputs.rate, numYears * 12);
  var lastAmortizationElement = amort[amort.length - 1];
  if (!lastAmortizationElement) {
    throw new Error('Unable to amortization data from mortgage calculatios');
  }
  return {
    totalInterest: lastAmortizationElement.totalInterest,
    totalPrincipal: lastAmortizationElement.totalPrincipal,
    monthlyPayment: monthlyPI(inputs.amount, inputs.rate, LOAN_TERM_IN_MONTHS[inputs.term]),
    payOffDate: inputs.loanStartMonth + ".  " + payOffYear
  };
}
function getAmortizationValues(lastValidFields) {
  var typeOneLoanInput = {
    term: lastValidFields.typeOneLoanTerm,
    amount: lastValidFields.typeOneLoanAmount,
    rate: lastValidFields.typeOneInterestRate,
    loanStartMonth: lastValidFields.typeOneLoanStartMonth,
    loanStartYear: lastValidFields.typeOneLoanStartYear
  };
  var typeOneCalculations = getAmortizationFieldValues(typeOneLoanInput);
  var typeTwoLoanInput = {
    term: lastValidFields.typeTwoLoanTerm,
    amount: lastValidFields.typeTwoLoanAmount,
    rate: lastValidFields.typeTwoInterestRate,
    loanStartMonth: lastValidFields.typeTwoLoanStartMonth,
    loanStartYear: lastValidFields.typeTwoLoanStartYear
  };
  var typeTwoCalculations = getAmortizationFieldValues(typeTwoLoanInput);
  var typeThreeLoanInput = {
    term: lastValidFields.typeThreeLoanTerm,
    amount: lastValidFields.typeThreeLoanAmount,
    rate: lastValidFields.typeThreeInterestRate,
    loanStartMonth: lastValidFields.typeThreeLoanStartMonth,
    loanStartYear: lastValidFields.typeThreeLoanStartYear
  };
  var typeThreeCalculations = getAmortizationFieldValues(typeThreeLoanInput);
  var values = {
    fields: lastValidFields,
    typeOneCalculations: typeOneCalculations,
    typeTwoCalculations: typeTwoCalculations,
    typeThreeCalculations: typeThreeCalculations
  };
  return values;
}
var calculatorDisclaimerText = 'The loan comparison calculator is intended to be used for illustrative purposes only. Actual available rates and monthly payment amounts are subject to your credit profile, market fluctuations, and other factors, including geography and loan characteristics. The estimates are based on information you provide, and does not include other fees and costs that a lender may assess in addition to monthly principal and interest, such as taxes and insurance and the actual payment obligation may be greater. Zillow Group Marketplace, Inc. does not make loans and this is not a commitment to lend.';

export { FieldConfig, calculatorDisclaimerText, getAmortizationFieldValues, useLastValidFormValues };
