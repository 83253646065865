import { event } from '@zillow/universal-analytics';

var triggerFormFieldEngagementClickstreamEvent = function triggerFormFieldEngagementClickstreamEvent(label) {
  var newLaneData = {
    envelope: {
      event_type_id: '2056',
      event_type_version_id: '3',
      event_template_id: '126',
      event_template_version_id: '1'
    },
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'cms',
      trigger_source_nm: 'cms_form',
      trigger_object_nm: 'no_trigger_object'
    },
    semantic: {
      semantic_event_nm: 'cms_engagement'
    },
    cms_info: {
      block_type_cd: 'form_field',
      block_id: 'home_sale_proceeds_calc',
      block_displayed_txt: label
    }
  };
  event(newLaneData);
};

export { triggerFormFieldEngagementClickstreamEvent };
