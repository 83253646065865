import { taggedTemplateLiteralLoose as _taggedTemplateLiteralLoose } from '../../_virtual/_rollupPluginBabelHelpers.js';
import { inlineMediaQueryMixin, token, StyledAnchor, StyledButton, StyledTextButton } from '@zillow/constellation';
import { css } from 'styled-components';
import Picture from '../../components/Picture/Picture.js';

var _templateObject, _templateObject2;
var widthMap = {
  xs: '320px',
  sm: '480px',
  md: '768px',
  lg: '1024px',
  xl: '1280px',
  full: '2560px'
};
var GROUP_WIDTH_MAP = widthMap;
var heightMap = {
  none: 'auto',
  short: '300px',
  tall: '500px',
  fluid: '100vh'
};
var ATTRIBUTES = ['background-color', 'font-color', 'min-height', 'max-width'];
var groupCSS = function groupCSS(props) {
  var styles = [];
  var usePixelMinHeight = false;
  Object.keys(props).forEach(function (prop) {
    var attr = prop.replace(/[A-Z]/g, function (char) {
      return "-" + char.toLowerCase();
    });
    if (ATTRIBUTES.indexOf(attr) >= 0) {
      styles.push(inlineMediaQueryMixin(prop, function (mediaProps) {
        var value = mediaProps[prop];
        var linkColor = value === 'textWhite' ? value : 'link';
        var linkHoverColor = value === 'textWhite' ? value : 'linkHover';
        if (attr === 'font-color') {
          return [token("colors." + value, function (color) {
            if (color) {
              return css(_templateObject || (_templateObject = _taggedTemplateLiteralLoose(["\n                                        color: ", ";\n                                    "])), color);
            }
            return '';
          }), css(_templateObject2 || (_templateObject2 = _taggedTemplateLiteralLoose(["\n                                ", ", a:not(", ", ", ") {\n                                    color: ", ";\n                                    &:hover {\n                                        color: ", ";\n                                    }\n                                }\n                            "])), StyledAnchor, StyledButton, StyledTextButton, token("colors." + linkColor), token("colors." + linkHoverColor))];
        }
        if (attr === 'background-color') {
          return token("colors." + value, function (color) {
            if (color) {
              return "background-color: " + color + ";";
            }
            return '';
          });
        }
        if (attr === 'max-width') {
          return "max-width: " + widthMap[value] + ";";
        }
        if (attr === 'min-height') {
          // If min-height is pixel, we'll use pixelMinHeight prop instead.
          if (value === 'pixel') {
            usePixelMinHeight = true;
            return '';
          }
          return "min-height: " + heightMap[value] + ";";
        }
        return attr + ": " + value + ";";
      })(props));
    }
  });
  if (usePixelMinHeight) {
    if ('minHeightPixel' in props) {
      styles.push(inlineMediaQueryMixin('minHeightPixel', function (mediaProps) {
        return "min-height: " + mediaProps.minHeightPixel + "px;";
      })(props));
    }
  }
  return styles;
};

// TODO: figure out a way to use tokens here instead
/** @class GroupMixin */
/** @method */
var GroupMixin = function GroupMixin(props) {
  return [groupCSS(props), "\n        position: relative;\n        margin-left: auto;\n        margin-right: auto;\n        width: 100%;\n        > *:not(" + Picture + ") {\n            position:relative;\n        }\n    "];
};

export { GROUP_WIDTH_MAP, GroupMixin };
