import { useFormContext } from 'react-hook-form';
import { useLastValidValues } from '../../utils/form/useLastValidValues.js';
import { COMMON_AMOUNT_CONFIG } from '../../utils/zhl-calculator.js';

var FieldConfig = {
  desiredSellingPrice: {
    name: 'desiredSellingPrice',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  remainingMortgageOwed: {
    name: 'remainingMortgageOwed',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  homeImprovement: {
    name: 'homeImprovement',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  cleaningAndPrep: {
    name: 'cleaningAndPrep',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  monthlyMortgagePayments: {
    name: 'monthlyMortgagePayments',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  moving: {
    name: 'moving',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  agentCommission: {
    name: 'agentCommission',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  sellingConcessions: {
    name: 'sellingConcessions',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  closingFees: {
    name: 'closingFees',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  taxes: {
    name: 'taxes',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  estimatedSellingCost: {
    name: 'estimatedSellingCost'
  }
};
var validateAndTrackOptionsBlock = {
  shouldDirty: true,
  shouldTouch: true,
  shouldValidate: true
};
function getHomeSaleProceedsValues(lastValidFields) {
  var desiredSellingPrice = lastValidFields.desiredSellingPrice,
    remainingMortgageOwed = lastValidFields.remainingMortgageOwed,
    homeImprovement = lastValidFields.homeImprovement,
    cleaningAndPrep = lastValidFields.cleaningAndPrep,
    monthlyMortgagePayments = lastValidFields.monthlyMortgagePayments,
    moving = lastValidFields.moving,
    agentCommission = lastValidFields.agentCommission,
    sellingConcessions = lastValidFields.sellingConcessions,
    closingFees = lastValidFields.closingFees,
    taxes = lastValidFields.taxes;
  var estimatedSellingCost = homeImprovement + cleaningAndPrep + monthlyMortgagePayments + moving + agentCommission + sellingConcessions + closingFees + taxes;
  var estimatedNetProceeds = desiredSellingPrice - estimatedSellingCost - remainingMortgageOwed;
  var estimatedSellingCostPercentage = Math.round(estimatedSellingCost / desiredSellingPrice * 10000) / 100;
  var calculations = {
    estimatedSellingCostResult: estimatedSellingCost,
    estimatedNetProceeds: estimatedNetProceeds,
    estimatedSellingCostPercentage: estimatedSellingCostPercentage
  };
  var values = {
    fields: lastValidFields,
    calculations: calculations
  };
  return values;
}
function useLastValidHomeSaleProceedsCalculator() {
  var form = useFormContext();
  return useLastValidValues(form, getHomeSaleProceedsValues);
}

export { FieldConfig, useLastValidHomeSaleProceedsCalculator, validateAndTrackOptionsBlock };
