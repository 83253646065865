import _debounce from 'lodash/debounce';
import { event } from '@zillow/universal-analytics';

/**
 *  Common function which calls sendInteractionEvent method with respective cmsInfo when onchange happens.
 *  @param {string} label - The displayed text/label.
 *  @param {string} block_type - The type of block for cmsInfo.
 *  @returns An event data object for analytics tracking..
 */
// Handle field change, debounced by 3 seconds.
var sendFieldInteractionEvent = _debounce(function (label, block_type) {
  var cmsInfo = {
    block_type_cd: block_type,
    block_id: ' refinance_calc',
    block_displayed_txt: label
  };
  sendInteractionEvent(cmsInfo, block_type);
}, 3000);

/**
 *  Common function which calls sendInteractionClickEvent method with respective cmsInfo when click action happens.
 *  @param {string} label - The displayed text/label.
 *  @param {string} block_type - The type of block for cmsInfo.
 *  @returns An event data object for analytics tracking..
 */
_debounce(function (label, block_type) {
  var cmsInfo = {
    block_type_cd: block_type,
    block_id: 'refinance_calc',
    block_displayed_txt: label
  };
  sendInteractionClickEvent(cmsInfo);
}, 3000);
var sendInteractionEvent = function sendInteractionEvent(cmsInfo, block_type) {
  var newLaneData = {
    envelope: {
      event_type_id: '2056',
      event_type_version_id: '2',
      event_template_id: '126',
      event_template_version_id: '1'
    },
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'cms',
      trigger_source_nm: block_type == 'form_field' ? 'cms_form' : 'cms_toggle',
      trigger_object_nm: 'no_trigger_object'
    },
    semantic: {
      semantic_event_nm: 'cms_engagement'
    },
    cms_info: cmsInfo
  };
  event(newLaneData);
};
var sendInteractionClickEvent = function sendInteractionClickEvent(cmsInfo) {
  var newLaneData = {
    envelope: {
      event_type_id: '2048',
      event_type_version_id: '3',
      event_template_id: '126',
      event_template_version_id: '1'
    },
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'cms',
      trigger_source_nm: 'cms_toggle',
      trigger_object_nm: 'no_trigger_object'
    },
    semantic: {
      semantic_event_nm: 'cms_engagement'
    },
    cms_info: cmsInfo
  };
  event(newLaneData);
};

export { sendFieldInteractionEvent };
