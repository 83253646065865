import { typeof as _typeof } from '../../_virtual/_rollupPluginBabelHelpers.js';
import { isSessionStorageSupported } from '@zillow/is-local-storage-supported';

var CREDIT_SCORE_LOW_FOR_CONNECT = {
  720: 'R_720_739',
  660: 'R_660_679',
  620: 'R_620_639',
  580: 'R_560_599',
  300: 'R_300_559'
};
var mapLongTableInput = function mapLongTableInput(answers) {
  var _answers$location, _answers$location2, _ref, _answers$creditScore;
  // NOTE: CQ quote page treats heloc as refinance
  var isPurchase = answers.loanType === 'purchase';
  var params = {
    auto: true,
    loantype: answers.loanType,
    zip: ((_answers$location = answers.location) === null || _answers$location === void 0 ? void 0 : _answers$location.zipCode) || ((_answers$location2 = answers.location) === null || _answers$location2 === void 0 ? void 0 : _answers$location2.name),
    value: answers.propertyValue,
    // TODO: unify Connect and CQ input
    // Check what's inside creditScore key, and if we need to do conversion
    cscore: (_ref = answers !== null && answers !== void 0 && (_answers$creditScore = answers.creditScore) !== null && _answers$creditScore !== void 0 && _answers$creditScore.creditScoreLow ? CREDIT_SCORE_LOW_FOR_CONNECT[answers.creditScore.creditScoreLow] : answers.creditScore) === null || _ref === void 0 || (_ref = _ref.replace('R_', '')) === null || _ref === void 0 || (_ref = _ref.replace('_', '-')) === null || _ref === void 0 ? void 0 : _ref.replace('760-', '760+'),
    va: answers.vaEligible,
    type: answers.propertyType || 'SingleFamilyHome',
    use: answers.propertyUsage || 'Primary'
  };
  if (isPurchase) {
    params.down = answers.downPayment;
  } else {
    if (answers.cashOut) {
      params.cashOut = answers.helocAmount;
    }
    params.currentbalance = answers.loanBalance;
  }
  return params;
};
var getItem = function getItem(storageKey) {
  if (isSessionStorageSupported()) {
    return sessionStorage.getItem(storageKey);
  }
};
var getMapObject = function getMapObject(storageKey) {
  try {
    var item = JSON.parse(getItem(storageKey));
    if (item && _typeof(item) === 'object' && !Array.isArray(item)) {
      return item;
    }
  } catch (_unused) {} // eslint-disable-line no-empty
};

export { getItem, getMapObject, mapLongTableInput };
