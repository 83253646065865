var _PAGE_FLAVOR_LOAN_TYP;
var LOAN_AMOUNT_RATE_BUCKET = {
  MICRO: 'Micro',
  SMALL_CONFORMING: 'SmallConforming',
  CONFORMING: 'Conforming',
  UNSURE: 'Unsure'
};
var RATE_TYPE = {
  STANDARD: 'Standard',
  NON_STANDARD: 'Non-standard',
  INVALID: 'Invalid'
};
var LOAN_TO_VALUE_RATE_BUCKET = {
  NORMAL: 'Normal',
  HIGH: 'High',
  VERY_HIGH: 'VeryHigh'
};
var standardRate = {
  Fixed30Year: 4.176
};
var REGIONALIZED_PAGES = ['mortgage-rates', 'refinance', 'lender-directory'];
var PAGE_FLAVOR = {
  Purchase: 'mortgage-rates',
  VA: 'va-mortgage-rates',
  VARefinance: 'va-refinance-rates',
  Refinance: 'refinance',
  Fixed30Year: '30-year-fixed',
  Fixed15Year: '15-year-fixed',
  Fixed20Year: '20-year-fixed',
  Fixed10Year: '10-year-fixed',
  ARM7: '7-year-arm',
  ARM5: '5-year-arm',
  ARM3: '3-year-arm',
  State: 'state',
  City: 'city',
  FHA: 'fha-mortgage-rates',
  Jumbo: 'jumbo-mortgage-rates',
  LoanType: 'PAGE_FLAVOR_LOAN_TYPE_MAP'
};
var LOAN_TYPES = {
  FHA: 'FHA',
  VA: 'VA',
  Conventional: 'Conventional',
  Jumbo: 'Jumbo'
};
var PAGE_FLAVOR_LOAN_TYPE_MAP = (_PAGE_FLAVOR_LOAN_TYP = {}, _PAGE_FLAVOR_LOAN_TYP[PAGE_FLAVOR.Purchase] = LOAN_TYPES.Conventional, _PAGE_FLAVOR_LOAN_TYP[PAGE_FLAVOR.VA] = LOAN_TYPES.VA, _PAGE_FLAVOR_LOAN_TYP[PAGE_FLAVOR.VARefinance] = LOAN_TYPES.VA, _PAGE_FLAVOR_LOAN_TYP[PAGE_FLAVOR.FHA] = LOAN_TYPES.FHA, _PAGE_FLAVOR_LOAN_TYP[PAGE_FLAVOR.Jumbo] = LOAN_TYPES.Jumbo, _PAGE_FLAVOR_LOAN_TYP);
var DEFAULT_PARTNER_ID = 'RD-CZMBMCZ';

export { DEFAULT_PARTNER_ID, LOAN_AMOUNT_RATE_BUCKET, LOAN_TO_VALUE_RATE_BUCKET, LOAN_TYPES, PAGE_FLAVOR, PAGE_FLAVOR_LOAN_TYPE_MAP, RATE_TYPE, REGIONALIZED_PAGES, standardRate };
