import { slicedToArray as _slicedToArray } from '../../_virtual/_rollupPluginBabelHelpers.js';
import { useState, useEffect } from 'react';
import { getZillowURL } from './ClosingCostCalculator.utils.js';
import { isWebview } from '@zillow/mortgage-webview-query-params';
import querystring from 'querystring';
import _omitBy from 'lodash/omitBy';
import _isNil from 'lodash/isNil';

function useCurrentRatesButtonUrl(pathBase, params) {
  if (pathBase === void 0) {
    pathBase = 'mortgage-rates';
  }
  if (params === void 0) {
    params = {};
  }
  var _useState = useState(''),
    _useState2 = _slicedToArray(_useState, 2),
    url = _useState2[0],
    setUrl = _useState2[1];
  useEffect(function () {
    var path = isWebview() ? 'mortgage/webviews/ZCQ.htm' : pathBase + "/";
    var queryString = querystring.stringify(_omitBy(params, _isNil));
    if (queryString) {
      queryString = "?" + queryString;
    }
    setUrl(getZillowURL(path + queryString));
  }, [pathBase, params]);
  return url;
}

export { useCurrentRatesButtonUrl };
