import { useFormContext } from 'react-hook-form';
import API from '@zillow/mortgage-api-client';
import { useLastValidValues } from '../../utils/form/useLastValidValues.js';
import { DEFAULT_PARTNER_ID } from '../../utils/mortgagehelpers/constants.js';
import { COMMON_AMOUNT_CONFIG, COMMON_PERCENT_CONFIG, getIsAmountNanError, castAsNumberIfNotNan } from '../../utils/zhl-calculator.js';

var DISCOUNT_POINTS = {
  defaultPoint: 'defaultPoint',
  oneDiscountPoint: 'oneDiscountPoint',
  twoDiscountPoints: 'twoDiscountPoints'
};
var NON_REQUIRED_COMMON_AMOUNT_CONFIG = {
  validate: {
    nan: getIsAmountNanError('Must be numeric amount.')
  },
  setValueAs: castAsNumberIfNotNan
};
var FieldConfig = {
  homePrice: {
    name: 'homePrice',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  downPayment: {
    name: 'downPayment',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  downPaymentPercent: {
    name: 'downPaymentPercent',
    config: Object.assign({}, COMMON_PERCENT_CONFIG)
  },
  interestRate: {
    name: 'interestRate',
    config: Object.assign({}, COMMON_PERCENT_CONFIG)
  },
  purchasingAnyDiscountPoints: {
    name: 'purchasingAnyDiscountPoints',
    options: [{
      value: DISCOUNT_POINTS.defaultPoint,
      label: 'No'
    }, {
      value: DISCOUNT_POINTS.oneDiscountPoint,
      label: 'Yes, 1 point'
    }, {
      value: DISCOUNT_POINTS.twoDiscountPoints,
      label: 'Yes, 2 points'
    }],
    config: {
      required: 'Required'
    }
  },
  loanOriginationFee: {
    name: 'loanOriginationFee',
    config: Object.assign({}, NON_REQUIRED_COMMON_AMOUNT_CONFIG)
  },
  discountPoints: {
    name: 'discountPoints',
    config: Object.assign({}, NON_REQUIRED_COMMON_AMOUNT_CONFIG)
  },
  creditReportFee: {
    name: 'creditReportFee',
    config: Object.assign({}, NON_REQUIRED_COMMON_AMOUNT_CONFIG)
  },
  titleSearchFee: {
    name: 'titleSearchFee',
    config: Object.assign({}, NON_REQUIRED_COMMON_AMOUNT_CONFIG)
  },
  titleInsurance: {
    name: 'titleInsurance',
    config: Object.assign({}, NON_REQUIRED_COMMON_AMOUNT_CONFIG)
  },
  attorneyFees: {
    name: 'attorneyFees',
    config: Object.assign({}, NON_REQUIRED_COMMON_AMOUNT_CONFIG)
  },
  settelementOrEscrowFee: {
    name: 'settelementOrEscrowFee',
    config: Object.assign({}, NON_REQUIRED_COMMON_AMOUNT_CONFIG)
  },
  homeownerInsurance: {
    name: 'homeownerInsurance',
    config: Object.assign({}, NON_REQUIRED_COMMON_AMOUNT_CONFIG)
  },
  propertyTaxes: {
    name: 'propertyTaxes',
    config: Object.assign({}, NON_REQUIRED_COMMON_AMOUNT_CONFIG)
  },
  prepaidInterest: {
    name: 'prepaidInterest',
    config: Object.assign({}, NON_REQUIRED_COMMON_AMOUNT_CONFIG)
  },
  appraisalFee: {
    name: 'appraisalFee',
    config: Object.assign({}, NON_REQUIRED_COMMON_AMOUNT_CONFIG)
  },
  homeInspectionFee: {
    name: 'homeInspectionFee',
    config: Object.assign({}, NON_REQUIRED_COMMON_AMOUNT_CONFIG)
  },
  pestInspectionFee: {
    name: 'pestInspectionFee',
    config: Object.assign({}, NON_REQUIRED_COMMON_AMOUNT_CONFIG)
  },
  surveyFee: {
    name: 'surveyFee',
    config: Object.assign({}, NON_REQUIRED_COMMON_AMOUNT_CONFIG)
  },
  recordingFee: {
    name: 'recordingFee',
    config: Object.assign({}, NON_REQUIRED_COMMON_AMOUNT_CONFIG)
  },
  courierFee: {
    name: 'courierFee',
    config: Object.assign({}, NON_REQUIRED_COMMON_AMOUNT_CONFIG)
  },
  notaryFee: {
    name: 'notaryFee',
    config: Object.assign({}, NON_REQUIRED_COMMON_AMOUNT_CONFIG)
  },
  hoaTransferFee: {
    name: 'hoaTransferFee',
    config: Object.assign({}, NON_REQUIRED_COMMON_AMOUNT_CONFIG)
  },
  floodCertificationFee: {
    name: 'floodCertificationFee',
    config: Object.assign({}, NON_REQUIRED_COMMON_AMOUNT_CONFIG)
  },
  bankProcessingFee: {
    name: 'bankProcessingFee',
    config: Object.assign({}, NON_REQUIRED_COMMON_AMOUNT_CONFIG)
  },
  propertyTaxRate: {
    name: 'propertyTaxRate',
    config: Object.assign({}, COMMON_PERCENT_CONFIG)
  }
};
var validateAndTrackOptionsBlock = {
  shouldDirty: true,
  shouldTouch: true,
  shouldValidate: true
};
function isSameFloat(float1, float2) {
  var nextVal = typeof float1 === 'string' ? Number(float1) : float1;
  var lastVal = typeof float2 === 'string' ? Number(float2) : float2;
  return Math.abs(Number(nextVal === null || nextVal === void 0 ? void 0 : nextVal.toFixed(2)) - Number(lastVal === null || lastVal === void 0 ? void 0 : lastVal.toFixed(2))) < Number.EPSILON;
}
var getZillowURL = function getZillowURL(path) {
  var zillowOrigin = 'https://www.zillow.com';
  return zillowOrigin + "/" + path.replace(/^\//, '');
};
function useLastValidClosingCostCalculator() {
  var form = useFormContext();
  return useLastValidValues(form, getClosingCostValues);
}
var fetchRate = function fetchRate(homePrice, downPayment) {
  return new Promise(function ($return, $error) {
    var rate;
    return Promise.resolve(fetchInterestRate({
      homePrice: homePrice,
      downPayment: downPayment
    })).then(function ($await_3) {
      try {
        rate = $await_3;
        return $return(rate == 0 ? standardRate.Fixed30Year : rate);
      } catch ($boundEx) {
        return $error($boundEx);
      }
    }, $error);
  });
};
var getZeroIfFieldEmpty = function getZeroIfFieldEmpty(field) {
  var fieldVal = typeof field === 'string' ? Number(field) : field;
  return !fieldVal ? 0 : fieldVal;
};
var roundToTheNearestTenth = function roundToTheNearestTenth(val) {
  return parseFloat(val.toFixed(1));
};
var roundToNearestInteger = function roundToNearestInteger(val) {
  return Math.round(val);
};
function getClosingCostValues(lastValidFields) {
  var homePrice = lastValidFields.homePrice,
    loanOriginationFee = lastValidFields.loanOriginationFee,
    creditReportFee = lastValidFields.creditReportFee,
    discountPoints = lastValidFields.discountPoints,
    titleSearchFee = lastValidFields.titleSearchFee,
    titleInsurance = lastValidFields.titleInsurance,
    attorneyFees = lastValidFields.attorneyFees,
    settelementOrEscrowFee = lastValidFields.settelementOrEscrowFee,
    homeownerInsurance = lastValidFields.homeownerInsurance,
    propertyTaxes = lastValidFields.propertyTaxes,
    prepaidInterest = lastValidFields.prepaidInterest,
    appraisalFee = lastValidFields.appraisalFee,
    homeInspectionFee = lastValidFields.homeInspectionFee,
    pestInspectionFee = lastValidFields.pestInspectionFee,
    surveyFee = lastValidFields.surveyFee,
    recordingFee = lastValidFields.recordingFee,
    courierFee = lastValidFields.courierFee,
    notaryFee = lastValidFields.notaryFee,
    hoaTransferFee = lastValidFields.hoaTransferFee,
    floodCertificationFee = lastValidFields.floodCertificationFee,
    bankProcessingFee = lastValidFields.bankProcessingFee;
  var closingCost = getZeroIfFieldEmpty(loanOriginationFee) + getZeroIfFieldEmpty(creditReportFee) + getZeroIfFieldEmpty(discountPoints) + getZeroIfFieldEmpty(titleSearchFee) + getZeroIfFieldEmpty(titleInsurance) + getZeroIfFieldEmpty(attorneyFees) + getZeroIfFieldEmpty(settelementOrEscrowFee) + getZeroIfFieldEmpty(homeownerInsurance) + getZeroIfFieldEmpty(propertyTaxes) + getZeroIfFieldEmpty(prepaidInterest) + getZeroIfFieldEmpty(appraisalFee) + getZeroIfFieldEmpty(homeInspectionFee) + getZeroIfFieldEmpty(pestInspectionFee) + getZeroIfFieldEmpty(surveyFee) + getZeroIfFieldEmpty(recordingFee) + getZeroIfFieldEmpty(courierFee) + getZeroIfFieldEmpty(notaryFee) + getZeroIfFieldEmpty(hoaTransferFee) + getZeroIfFieldEmpty(floodCertificationFee) + getZeroIfFieldEmpty(bankProcessingFee);
  var calculations = {
    closingCost: closingCost,
    closingCostPercentage: Number((closingCost / homePrice * 100).toFixed(1))
  };
  var values = {
    fields: lastValidFields,
    calculations: calculations
  };
  return values;
}
var standardRate = {
  Fixed30Year: 4.176
};
var LOAN_TO_VALUE_RATE_BUCKET = {
  NORMAL: 'Normal',
  HIGH: 'High',
  VERY_HIGH: 'VeryHigh'
};
var LOAN_AMOUNT_RATE_BUCKET = {
  MICRO: 'Micro',
  SMALL_CONFORMING: 'SmallConforming',
  CONFORMING: 'Conforming',
  UNSURE: 'Unsure'
};
var RATE_TYPE = {
  STANDARD: 'Standard',
  NON_STANDARD: 'Non-standard',
  INVALID: 'Invalid'
};
function getLoanToValueRateBucket(homePrice, downPayment) {
  if (typeof homePrice === 'undefined' || typeof downPayment === 'undefined') {
    return LOAN_TO_VALUE_RATE_BUCKET.NORMAL;
  }
  var downPercent = downPayment / homePrice * 100;
  if (downPercent < 5) {
    return LOAN_TO_VALUE_RATE_BUCKET.VERY_HIGH;
  } else if (downPercent >= 5 && downPercent < 20) {
    return LOAN_TO_VALUE_RATE_BUCKET.HIGH;
  }
  return LOAN_TO_VALUE_RATE_BUCKET.NORMAL;
}
function getLoanAmountRateBucket(homePrice, downPayment) {
  if (typeof homePrice === 'undefined' || typeof downPayment === 'undefined') {
    return LOAN_AMOUNT_RATE_BUCKET.UNSURE;
  }
  var remainder = homePrice - downPayment;
  if (remainder < 100000) {
    return LOAN_AMOUNT_RATE_BUCKET.MICRO;
  } else if (remainder >= 100000 && remainder < 200000) {
    return LOAN_AMOUNT_RATE_BUCKET.SMALL_CONFORMING;
  } else if (remainder >= 200000 && remainder < 453100) {
    return LOAN_AMOUNT_RATE_BUCKET.CONFORMING;
  }
  return LOAN_AMOUNT_RATE_BUCKET.UNSURE;
}
function getCreditScoreBucket(creditScore) {
  var buckets = {
    R_760_: 'VeryHigh',
    R_740_759: 'VeryHigh',
    R_720_739: 'High',
    R_700_719: 'High',
    R_680_699: 'High',
    R_660_679: 'Low',
    R_640_659: 'Low',
    R_620_639: 'Low',
    R_600_619: 'Low',
    R_560_599: 'Low',
    R_300_559: 'Low'
  };
  return creditScore && buckets[creditScore];
}
function calculateRateBuckets(_ref) {
  var homePrice = _ref.homePrice,
    downPayment = _ref.downPayment,
    loanProgram = _ref.loanProgram,
    creditScore = _ref.creditScore;
  var loanToValueRateBucket = getLoanToValueRateBucket(homePrice, downPayment);
  var loanAmountRateBucket = getLoanAmountRateBucket(homePrice, downPayment);
  var creditScoreBucket = getCreditScoreBucket(creditScore);
  var result = {
    rateType: RATE_TYPE.STANDARD,
    loanProgram: loanProgram,
    loanAmountBucket: loanAmountRateBucket,
    loanToValueBucket: loanToValueRateBucket,
    creditScoreBucket: creditScoreBucket
  };
  if (result.loanAmountBucket !== LOAN_AMOUNT_RATE_BUCKET.CONFORMING || result.loanToValueBucket !== LOAN_TO_VALUE_RATE_BUCKET.NORMAL || result.creditScoreBucket !== 'VeryHigh') {
    result.rateType = RATE_TYPE.NON_STANDARD;
  }
  if (homePrice - downPayment < 0) {
    result.rateType = RATE_TYPE.INVALID;
  }
  return result;
}
function getRateQuery(_ref2) {
  var loanAmountParam = _ref2.loanAmount,
    homePrice = _ref2.homePrice,
    downPayment = _ref2.downPayment,
    _ref2$loanProgram = _ref2.loanProgram,
    loanProgram = _ref2$loanProgram === void 0 ? 'Fixed30Year' : _ref2$loanProgram,
    _ref2$creditScore = _ref2.creditScore,
    creditScore = _ref2$creditScore === void 0 ? 'R_720_739' : _ref2$creditScore,
    _ref2$va = _ref2.va,
    va = _ref2$va === void 0 ? false : _ref2$va,
    _ref2$refinance = _ref2.refinance,
    refinance = _ref2$refinance === void 0 ? false : _ref2$refinance,
    _ref2$fha = _ref2.fha,
    fha = _ref2$fha === void 0 ? false : _ref2$fha,
    _ref2$jumbo = _ref2.jumbo,
    jumbo = _ref2$jumbo === void 0 ? false : _ref2$jumbo;
  var rateBucketResults = calculateRateBuckets({
    homePrice: homePrice,
    downPayment: downPayment,
    loanProgram: loanProgram,
    creditScore: creditScore
  });
  var rateQuery = {
    stateAbbreviation: API.stateAbbreviation,
    program: rateBucketResults.loanProgram
  };
  if (rateBucketResults.loanAmountBucket !== LOAN_AMOUNT_RATE_BUCKET.UNSURE) {
    // The loan amount is not a Jumbo loan in any county, so call with generic enums for better caching
    rateQuery.loanAmountBucket = rateBucketResults.loanAmountBucket;
    rateQuery.loanToValueBucket = rateBucketResults.loanToValueBucket;
  } else {
    if (homePrice) {
      // The loan amount may be a Jumbo loan, depending on county, so let the MAX decide based on exact inputs
      var loanAmount = loanAmountParam || homePrice - downPayment;
      rateQuery.propertyBucket = {
        propertyValue: homePrice,
        loanAmount: loanAmount,
        location: {
          stateAbbreviation: API.stateAbbreviation
        }
      };
    }
  }
  if (va) rateQuery.loanType = 'VA';
  if (rateBucketResults.creditScoreBucket) rateQuery.creditScoreBucket = rateBucketResults.creditScoreBucket;
  if (refinance) rateQuery.refinance = true;
  if (fha) rateQuery.loanType = 'FHA';
  if (jumbo) rateQuery.loanType = 'Jumbo';
  return rateQuery;
}
function getStandardInterestRate(loanProgram) {
  if (loanProgram === void 0) {
    loanProgram = 'Fixed30Year';
  }
  return standardRate[loanProgram];
}
var getCurrentRates = function getCurrentRates(inputs) {
  return new Promise(function ($return, $error) {
    if (!API || !API.MAX || !API.MAX.GET) {
      return $return(Promise.resolve({
        rates: {
          rateQuery: {
            rate: 0
          }
        }
      }));
    }
    var $Try_1_Catch = function (error) {
      try {
        return $return({
          rates: {
            rateQuery: {
              rate: 0
            }
          }
        });
      } catch ($boundEx) {
        return $error($boundEx);
      }
    };
    try {
      return Promise.resolve(API.MAX.GET({
        action: 'getCurrentRates',
        timeout: 3000,
        input: inputs
      })).then($return, $Try_1_Catch);
    } catch (error) {
      $Try_1_Catch();
    }
  });
};
var fetchInterestRate = function fetchInterestRate(rateQueryParams) {
  var loanProgram = rateQueryParams.loanProgram;
  var standardRate = getStandardInterestRate(loanProgram);
  return getCurrentRates({
    partnerId: API.partnerId ? API.partnerId : DEFAULT_PARTNER_ID,
    queries: {
      rateQuery: getRateQuery(rateQueryParams)
    }
  }).then(function (response) {
    var newRate = response.rates.rateQuery.rate;
    if (newRate === 0) {
      // This happens when the number of quotes the rate is based on is 0, so just
      // use defaults in this case
      return standardRate;
    } else {
      return newRate;
    }
  });
};
var getTaxRate = function getTaxRate(apiInput) {
  return new Promise(function ($return, $error) {
    if (!API || !API.MAX || !API.MAX.GET) {
      return $return(Promise.resolve({
        rate: 0
      }));
    }
    var $Try_2_Catch = function (error) {
      try {
        return $return({
          rate: 0
        });
      } catch ($boundEx) {
        return $error($boundEx);
      }
    };
    try {
      return Promise.resolve(API.MAX.GET({
        action: 'getAnnualPropertyTaxRate',
        timeout: 3000,
        input: apiInput
      })).then($return, $Try_2_Catch);
    } catch (error) {
      $Try_2_Catch();
    }
  });
};
var isValidZipcode = function isValidZipcode(value) {
  var regex = /^\d{5}$/;
  return regex.test(value);
};
var fetchPropertyTaxRate = function fetchPropertyTaxRate() {
  return new Promise(function ($return, $error) {
    var apiInput = {
      partnerId: API.partnerId ? API.partnerId : DEFAULT_PARTNER_ID
    };
    var zipcode = API.zipcode;
    if (zipcode && isValidZipcode(zipcode) && zipcode.length === 5) {
      apiInput['zipCode'] = zipcode;
    } else {
      apiInput['stateAbbreviation'] = API.stateAbbreviation;
    }
    return $return(getTaxRate(apiInput).then(function (response) {
      var newRate = response.rate;
      return newRate;
    }));
  });
};

export { DISCOUNT_POINTS, FieldConfig, LOAN_AMOUNT_RATE_BUCKET, LOAN_TO_VALUE_RATE_BUCKET, NON_REQUIRED_COMMON_AMOUNT_CONFIG, RATE_TYPE, calculateRateBuckets, fetchInterestRate, fetchPropertyTaxRate, fetchRate, getCurrentRates, getLoanAmountRateBucket, getLoanToValueRateBucket, getRateQuery, getStandardInterestRate, getTaxRate, getZillowURL, isSameFloat, roundToNearestInteger, roundToTheNearestTenth, standardRate, useLastValidClosingCostCalculator, validateAndTrackOptionsBlock };
