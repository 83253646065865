import { useLastValidValues } from '../../utils/form/useLastValidValues.js';
import PropTypes from 'prop-types';
import API from '@zillow/mortgage-api-client';
import { STATE_NAMES, LOAN_PROGRAMS } from './constants.js';
import { LOAN_TYPES, LOAN_SIZES } from '../MortgageRatesTable/MortgageRatesTable.js';
import { PAGE_FLAVOR_LOAN_TYPE_MAP } from '../../utils/mortgagehelpers/constants.js';
import { ABBREVIATIONS_TO_FULL_NAMES } from '@zillow/state-region-id-map';

var OPTIONS_LOAN_TYPE = {
  Purchase: 'Purchase',
  Refinance: 'Refinance'
};
var CREDIT_SCORE_BUCKETS = {
  VeryHigh: '740 or higher',
  High: '680 - 740',
  Low: 'Less than 680'
};
var OPTIONS_LTV = {
  Normal: '20% or higher',
  High: '5% - 20%',
  VeryHigh: 'Less than 5%'
};
var OPTIONS_TIMESPAN = {
  OneDay: '1',
  SevenDays: '7',
  OneMonth: '30',
  ThreeMonths: '90',
  OneYear: '365',
  TwoYears: '730'
};
var FieldConfig = {
  Fixed30Year: {
    name: 'Fixed30Year'
  },
  Fixed20Year: {
    name: 'Fixed20Year'
  },
  Fixed15Year: {
    name: 'Fixed15Year'
  },
  Fixed10Year: {
    name: 'Fixed10Year'
  },
  ARM7: {
    name: 'ARM7'
  },
  ARM5: {
    name: 'ARM5'
  },
  ARM3: {
    name: 'ARM3'
  },
  loanPurpose: {
    name: 'loanPurpose',
    options: [{
      value: 'Purchase',
      label: 'Purchase'
    }, {
      value: 'Refinance',
      label: 'Refinance'
    }],
    config: {
      validate: {
        invalidProgram: function invalidProgram(value) {
          var error = null;
          if (Object.values(OPTIONS_LOAN_TYPE).includes(value) === false) {
            error = 'Invalid loan purpose';
          }
          return error;
        }
      }
    }
  },
  creditRating: {
    name: 'creditRating',
    options: [{
      value: CREDIT_SCORE_BUCKETS.VeryHigh,
      label: CREDIT_SCORE_BUCKETS.VeryHigh
    }, {
      value: CREDIT_SCORE_BUCKETS.High,
      label: CREDIT_SCORE_BUCKETS.High
    }, {
      value: CREDIT_SCORE_BUCKETS.Low,
      label: CREDIT_SCORE_BUCKETS.Low
    }],
    config: {
      validate: {
        invalidProgram: function invalidProgram(value) {
          var error = null;
          if (Object.values(CREDIT_SCORE_BUCKETS).includes(value) === false) {
            error = 'Invalid credit rating';
          }
          return error;
        }
      }
    }
  },
  percentDown: {
    name: 'percentDown',
    options: [{
      value: OPTIONS_LTV.Normal,
      label: OPTIONS_LTV.Normal
    }, {
      value: OPTIONS_LTV.High,
      label: OPTIONS_LTV.High
    }, {
      value: OPTIONS_LTV.VeryHigh,
      label: OPTIONS_LTV.VeryHigh
    }],
    config: {
      validate: {
        invalidProgram: function invalidProgram(value) {
          var error = null;
          if (Object.values(OPTIONS_LTV).includes(value) === false) {
            error = 'Invalid percent down option';
          }
          return error;
        }
      }
    }
  },
  location: {
    name: 'location',
    options: STATE_NAMES.map(function (i) {
      return {
        value: i.toString(),
        label: i.toString()
      };
    }),
    config: {
      validate: {
        invalidProgram: function invalidProgram(value) {
          var error = null;
          if (STATE_NAMES.includes(value) === false) {
            error = 'Invalid location';
          }
          return error;
        }
      }
    }
  },
  timeSpan: {
    name: 'timeSpan',
    options: [{
      value: OPTIONS_TIMESPAN.OneDay,
      label: '1 day'
    }, {
      value: OPTIONS_TIMESPAN.SevenDays,
      label: '7 days'
    }, {
      value: OPTIONS_TIMESPAN.OneMonth,
      label: '1 month'
    }, {
      value: OPTIONS_TIMESPAN.ThreeMonths,
      label: '3 months'
    }, {
      value: OPTIONS_TIMESPAN.OneYear,
      label: '1 year'
    }, {
      value: OPTIONS_TIMESPAN.TwoYears,
      label: '2 years'
    }],
    config: {
      validate: {
        invalidProgram: function invalidProgram(value) {
          var error = null;
          if (Object.values(OPTIONS_TIMESPAN).includes(value) === false) {
            error = 'Invalid timespan';
          }
          return error;
        }
      }
    }
  }
};
({
  loanPurpose: PropTypes.string,
  creditRating: PropTypes.string,
  percentDown: PropTypes.string,
  location: PropTypes.string,
  timeSpan: PropTypes.string
});
function useSyncFetchHistory(form, flavor) {
  useLastValidValues(form, useSyncGenerateQuery);
  return getApiValuesFromFields(form.getValues(), flavor);
}
function useSyncGenerateQuery(lastValidFields) {
  return lastValidFields;
}
function getKeyByValue(object, value) {
  return Object.keys(object).find(function (key) {
    return object[key] === value;
  });
}
function getApiValuesFromFields(currentFieldValues, flavor) {
  var _PAGE_FLAVOR_LOAN_TYP;
  var queries = {};
  var loanProgramFields = [];
  var checkedLoanProgramFields = [];
  var loanType = (_PAGE_FLAVOR_LOAN_TYP = PAGE_FLAVOR_LOAN_TYPE_MAP[flavor]) !== null && _PAGE_FLAVOR_LOAN_TYP !== void 0 ? _PAGE_FLAVOR_LOAN_TYP : LOAN_TYPES.Conventional;
  var loanPrograms = Object.entries(currentFieldValues);
  for (var i = 0, l1 = loanPrograms.length; i < l1; i++) {
    LOAN_PROGRAMS[loanPrograms[i][0]] ? loanProgramFields.push(loanPrograms[i]) : null;
  }
  for (var _i = 0, _l = loanProgramFields.length; _i < _l; _i++) {
    loanProgramFields[_i][1] == true ? checkedLoanProgramFields.push(loanProgramFields[_i][0]) : null;
  }
  var location = getKeyByValue(ABBREVIATIONS_TO_FULL_NAMES, currentFieldValues === null || currentFieldValues === void 0 ? void 0 : currentFieldValues.location);
  var loanToValueBucket = getKeyByValue(OPTIONS_LTV, currentFieldValues.percentDown);
  var creditScoreBucket = getKeyByValue(CREDIT_SCORE_BUCKETS, currentFieldValues.creditRating);
  checkedLoanProgramFields === null || checkedLoanProgramFields === void 0 || checkedLoanProgramFields.forEach(function (program) {
    var _currentFieldValues$l;
    queries[program] = {
      stateAbbreviation: location,
      program: program,
      loanToValueBucket: loanToValueBucket,
      creditScoreBucket: creditScoreBucket,
      refinance: currentFieldValues !== null && currentFieldValues !== void 0 && (_currentFieldValues$l = currentFieldValues.loanPurpose) !== null && _currentFieldValues$l !== void 0 && _currentFieldValues$l.refinance ? true : false,
      loanType: loanType
    };
  });
  return {
    location: location,
    loanToValueBucket: loanToValueBucket,
    creditScoreBucket: creditScoreBucket,
    queries: queries,
    currentFieldValues: currentFieldValues,
    checkedLoanProgramFields: checkedLoanProgramFields
  };
}
//API used for RateHistoryTable for screen reader
function fetchApiResponse(inputValues) {
  return new Promise(function ($return, $error) {
    var _API$partnerId;
    var $Try_1_Catch = function (e) {
      try {
        return $return(e.message);
      } catch ($boundEx) {
        return $error($boundEx);
      }
    };
    try {
      return Promise.resolve(API === null || API === void 0 ? void 0 : API.MAX.GET({
        action: 'getRates',
        input: {
          partnerId: (_API$partnerId = API.partnerId) !== null && _API$partnerId !== void 0 ? _API$partnerId : 'RD-WXDQSGN',
          queries: inputValues.queries,
          durationDays: inputValues.currentFieldValues.timeSpan
        }
      })).then($return, $Try_1_Catch);
    } catch (e) {
      $Try_1_Catch(e);
    }
  });
}
var formatTableLabel = function formatTableLabel(_ref) {
  var stateAbbreviation = _ref.stateAbbreviation,
    durationDays = _ref.durationDays,
    loanAmountBucket = _ref.loanAmountBucket,
    loanToValueBucket = _ref.loanToValueBucket,
    creditScoreBucket = _ref.creditScoreBucket,
    program = _ref.program,
    refinance = _ref.refinance;
  var refinanceLabel = refinance ? 'refinance' : 'purchase';
  var durationLabel = typeof durationDays === 'number' ? durationDays + " days of " : '';
  var loanAmountLabel = LOAN_SIZES[loanAmountBucket] + " amount";
  var loanToValueLabel = OPTIONS_LTV[loanToValueBucket] + " loan to value";
  var creditScoreLabel = CREDIT_SCORE_BUCKETS[creditScoreBucket] + " credit score";
  var programLabel = "" + LOAN_PROGRAMS[program];
  var stateLabel = "" + (stateAbbreviation || 'US');
  return programLabel + " " + durationLabel + " " + refinanceLabel + " rates for someone with a " + creditScoreLabel + " and a " + loanToValueLabel + " planning to borrow a " + loanAmountLabel + " in the " + stateLabel + " region.";
};
var DAY_FORMAT = 'PPP';
var HOUR_FORMAT = 'PPPppp';
var getFormatDate = function getFormatDate(formatDate) {
  return function (date, duration) {
    return formatDate(new Date(date), duration < 30 ? HOUR_FORMAT : DAY_FORMAT);
  };
};

export { CREDIT_SCORE_BUCKETS, DAY_FORMAT, FieldConfig, HOUR_FORMAT, OPTIONS_LOAN_TYPE, OPTIONS_LTV, OPTIONS_TIMESPAN, fetchApiResponse, formatTableLabel, getFormatDate, useSyncFetchHistory, useSyncGenerateQuery };
