import _omit from 'lodash/omit';
import PropTypes from 'prop-types';
import calculatePayment, { affordability, monthlyPI, monthlyPMI } from '@zillow/mortgage-calculations';
import { useLastValidValues } from '../../utils/form/useLastValidValues.js';
import { COMMON_AMOUNT_CONFIG, COMMON_PERCENT_CONFIG, castAsNumberIfNotNan, getIsNanError } from '../../utils/zhl-calculator.js';

var calculateAffordability = affordability.default,
  affordabilityDefaults = affordability.affordabilityDefaults;
var AFFORDABILITY_DTI_MAX = 50;
function isAggressive(_ref) {
  var dti = _ref.dti;
  return dti > 36;
}
var FieldConfig = {
  customPayment: {
    name: 'customPayment'
  },
  maximumPayment: {
    name: 'maximumPayment'
    // No config is needed, as this field is programatically controlled. Also
    // if it does get invalid, the user cannot address it, as there is no
    // corresponding field for it, visually. The reason it is still a field
    // though is because a default value can be set.
  },
  annualIncome: {
    name: 'annualIncome',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  monthlyDebts: {
    name: 'monthlyDebts',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  downPayment: {
    name: 'downPayment',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  dti: {
    name: 'dti',
    config: Object.assign({
      max: {
        value: AFFORDABILITY_DTI_MAX,
        message: "Must be " + AFFORDABILITY_DTI_MAX + " at most."
      }
    }, COMMON_PERCENT_CONFIG)
  },
  rate: {
    name: 'rate',
    config: Object.assign({}, _omit(COMMON_PERCENT_CONFIG, 'min'), {
      validate: Object.assign({}, COMMON_PERCENT_CONFIG.validate, {
        getIsLessThanOrEqualToZeroError: function getIsLessThanOrEqualToZeroError(value) {
          var error = null;
          var castedNumber = Number(value);
          if (castedNumber <= 0) {
            error = 'Must be greater than 0%.';
          }
          return error;
        }
      })
    })
  },
  term: {
    name: 'term',
    config: {
      setValueAs: castAsNumberIfNotNan,
      required: 'Required',
      min: {
        value: 1,
        message: 'Must be at least 1.'
      },
      max: {
        value: 500,
        message: 'Must be 500 at most.'
      },
      validate: {
        nan: getIsNanError('Invalid number')
      }
    }
  },
  includePMI: {
    name: 'includePMI'
  },
  includeTaxesInsurance: {
    name: 'includeTaxesInsurance'
  },
  propertyTaxRate: {
    name: 'propertyTaxRate',
    config: Object.assign({}, COMMON_PERCENT_CONFIG)
  },
  annualHomeownersInsurance: {
    name: 'annualHomeownersInsurance',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  monthlyHOA: {
    name: 'monthlyHOA',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  monthlyPayment: {
    name: 'monthlyPayment',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  }
};
var DEFAULT_VALUES_SHAPE = {
  customPayment: PropTypes.bool,
  maximumPayment: PropTypes.number,
  annualIncome: PropTypes.number,
  monthlyDebts: PropTypes.number,
  downPayment: PropTypes.number,
  dti: PropTypes.number,
  rate: PropTypes.number,
  term: PropTypes.number,
  includePMI: PropTypes.bool,
  includeTaxesInsurance: PropTypes.bool,
  propertyTaxRate: PropTypes.number,
  annualHomeownersInsurance: PropTypes.number,
  monthlyHOA: PropTypes.number,
  monthlyPayment: PropTypes.number
};
function getCalculatedAffordabiltyValues(currentFields) {
  var affordabilityValues = affordabilityDefaults(Object.assign({}, currentFields, {
    includeHomeownersInsurance: currentFields.includeTaxesInsurance,
    includeHOA: true,
    includePropertyTax: currentFields.includeTaxesInsurance
  }));
  var affordabilityOutputs = calculateAffordability(affordabilityValues);
  return Object.assign({}, affordabilityOutputs, {
    monthlyPayment: calculatePayment(Object.assign({
      homePrice: affordabilityOutputs.affordabilityByDTI
    }, affordabilityValues)).monthlyPayment
  });
}
function useLastValidAffordabilityValues(form) {
  return useLastValidValues(form, getAffordabilityValues);
}
function getAffordabilityValues(lastValidFields) {
  var affordability = getCalculatedAffordabiltyValues(lastValidFields);
  var calculations = {
    affordability: affordability,
    pi: monthlyPI(affordability.affordabilityByDTI - lastValidFields.downPayment, lastValidFields.rate, lastValidFields.term),
    pmi: monthlyPMI(affordability.affordabilityByDTI - lastValidFields.downPayment, affordability.affordabilityByDTI, false),
    aggressive: isAggressive(lastValidFields)
  };
  var values = {
    fields: lastValidFields,
    calculations: calculations
  };
  return values;
}

export { AFFORDABILITY_DTI_MAX, DEFAULT_VALUES_SHAPE, FieldConfig, getCalculatedAffordabiltyValues, isAggressive, useLastValidAffordabilityValues };
