import { event } from '@zillow/universal-analytics';

var PROPERTY_SEARCH_CONFIG_VALUES = {
  test: {
    addressAutocompleteURL: 'https://static.qa.zillow.net/autocomplete/v3/suggestions/',
    origin: 'https://www.qa.zillow.net'
  },
  prod: {
    addressAutocompleteURL: 'https://www.zillowstatic.com/autocomplete/v3/suggestions/',
    origin: 'https://www.zillow.com'
  }
};
var FSBO_POSTING_URL_PATH = '/posting/edit-property-location?address=';
var sendInteractionSubmitEvent = function sendInteractionSubmitEvent(targetUrl, blockId) {
  var newLaneData = {
    envelope: {
      event_type_id: '2055',
      event_type_version_id: '1',
      event_template_id: '126',
      event_template_version_id: '1'
    },
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'cms',
      trigger_source_nm: 'cms_button',
      trigger_object_nm: 'no_trigger_object'
    },
    semantic: {
      semantic_event_nm: 'cms_submit',
      topic_tags: []
    },
    cms_info: {
      block_type_cd: 'fsbo_prooperty_search',
      block_id: blockId !== null && blockId !== void 0 ? blockId : '',
      block_displayed_txt: 'Enter your address',
      form_business_line_txt: 'FSBO',
      block_target_url: targetUrl,
      form_submit_label: 'magnifying glass icon'
    }
  };
  event(newLaneData);
};

export { FSBO_POSTING_URL_PATH, PROPERTY_SEARCH_CONFIG_VALUES, sendInteractionSubmitEvent };
