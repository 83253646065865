import { useFormContext } from 'react-hook-form';
import { useLastValidValues } from '../../utils/form/useLastValidValues.js';
import { COMMON_AMOUNT_CONFIG } from '../../utils/zhl-calculator.js';

var FieldConfig = {
  homeValue: {
    name: 'homeValue',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG, {
      max: {
        value: 1000000000,
        message: 'Must be $1,000,000,000 at most.'
      }
    })
  },
  loanBalance: {
    name: 'loanBalance',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG, {
      max: {
        value: 800000,
        message: 'Must be $800,000 at most.'
      },
      min: {
        value: 1000,
        message: 'Must be at least $1,000.'
      }
    })
  }
};
function useLastValidHelocValues() {
  var form = useFormContext();
  return useLastValidValues(form, getHelocValues);
}
function getHelocValues(lastValidFields) {
  var combinedLoneToValueRatio = 0.85;
  var calculations = {
    creditLine: combinedLoneToValueRatio * lastValidFields.homeValue - lastValidFields.loanBalance
  };
  var values = {
    fields: lastValidFields,
    calculations: calculations
  };
  return values;
}

export { FieldConfig, useLastValidHelocValues };
