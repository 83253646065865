import { event } from '@zillow/universal-analytics';

var sendTextButtonInteractionEvent = function sendTextButtonInteractionEvent(cmsInfo) {
  var newLaneData = {
    envelope: {
      event_type_id: '2048',
      event_type_version_id: '3',
      event_template_id: '126',
      event_template_version_id: '1'
    },
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'cms',
      trigger_source_nm: 'cms_toggle',
      trigger_object_nm: 'no_trigger_object'
    },
    semantic: {
      semantic_event_nm: 'cms_engagement'
    },
    cms_info: cmsInfo
  };
  event(newLaneData);
};

export { sendTextButtonInteractionEvent };
