import { useFormContext } from 'react-hook-form';
import { monthlyPI } from '@zillow/mortgage-calculations';
import { useLastValidValues } from '../../utils/form/useLastValidValues.js';
import { LOAN_PROGRAMS, COMMON_AMOUNT_CONFIG, COMMON_PERCENT_CONFIG, LOAN_TERM_IN_MONTHS, commaSeparateNumber } from '../../utils/zhl-calculator.js';

var options = [{
  value: LOAN_PROGRAMS.fixed30Year,
  label: '30-year fixed'
}, {
  value: LOAN_PROGRAMS.fixed20Year,
  label: '20-year fixed'
}, {
  value: LOAN_PROGRAMS.fixed15Year,
  label: '15-year fixed'
}, {
  value: LOAN_PROGRAMS.fixed10Year,
  label: '10-year fixed'
}];
var cashOutputs = {
  adjustedEstimate: 'Adjusted estimate',
  combinedLoanToValue: 'Combined loan-to-value',
  newLoanBalance: 'New loan balance',
  newMonthlyPayment: 'New monthly payment'
};
var OUTPUTS = [cashOutputs.adjustedEstimate, cashOutputs.combinedLoanToValue, cashOutputs.newLoanBalance, cashOutputs.newMonthlyPayment];
var formatAmount = function formatAmount(amount) {
  return '$' + commaSeparateNumber(amount);
};
var getResult = function getResult(label, values) {
  var calculations = values.calculations;
  if (label === cashOutputs.adjustedEstimate) {
    return formatAmount(calculations.adjustedCashOutEstimate);
  } else if (label === cashOutputs.combinedLoanToValue) {
    return Number(calculations.combinedLoanToValue.toFixed(2)) + '%';
  } else if (label === cashOutputs.newLoanBalance) {
    return formatAmount(calculations.newLoanBalance);
  } else {
    return formatAmount(Math.round(calculations.monthlyPI));
  }
};
var FieldConfig = {
  homeValue: {
    name: 'homeValue',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  currentMortgageBalance: {
    name: 'currentMortgageBalance',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  newLoanTerm: {
    name: 'newLoanTerm',
    options: options,
    config: {
      required: 'Required',
      validate: {
        invalidProgram: function invalidProgram(value) {
          var error = null;
          if (Object.values(LOAN_PROGRAMS).includes(value) === false) {
            error = 'Invalid loan program';
          }
          return error;
        }
      }
    }
  },
  newInterestRate: {
    name: 'newInterestRate',
    config: Object.assign({}, COMMON_PERCENT_CONFIG)
  },
  adjustCashOutAmount: {
    name: 'adjustCashOutAmount',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  fees: {
    name: 'fees',
    config: Object.assign({}, COMMON_AMOUNT_CONFIG)
  },
  rollFees: {
    name: 'rollFees'
  }
};
function useLastValidCashOutRefinanceValues() {
  var form = useFormContext();
  return useLastValidValues(form, getCashOutRefinanceValues);
}
function getCashOutRefinanceValues(lastValidFields) {
  var homeValue = lastValidFields.homeValue,
    currentMortgageBalance = lastValidFields.currentMortgageBalance,
    newInterestRate = lastValidFields.newInterestRate,
    newLoanTerm = lastValidFields.newLoanTerm,
    adjustCashOutAmount = lastValidFields.adjustCashOutAmount,
    rollFees = lastValidFields.rollFees,
    fees = lastValidFields.fees;
  var includeFees = rollFees ? fees : 0;
  var EstimatedMaxCashOutAmount = homeValue * 80 / 100 - currentMortgageBalance;
  var newLoanBalance = currentMortgageBalance + adjustCashOutAmount + includeFees;
  var combinedLoanToValue = newLoanBalance / homeValue * 100;
  var adjustedCashOutEstimate = homeValue * (combinedLoanToValue / 100) - (currentMortgageBalance + includeFees);
  var calculations = {
    EstimatedMaxCashOutAmount: EstimatedMaxCashOutAmount,
    combinedLoanToValue: combinedLoanToValue,
    newLoanBalance: newLoanBalance,
    adjustedCashOutEstimate: adjustedCashOutEstimate,
    monthlyPI: monthlyPI(newLoanBalance, newInterestRate, LOAN_TERM_IN_MONTHS[newLoanTerm])
  };
  var values = {
    fields: lastValidFields,
    calculations: calculations
  };
  return values;
}

export { FieldConfig, OUTPUTS, cashOutputs, getResult, useLastValidCashOutRefinanceValues };
