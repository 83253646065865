import { ApolloClient, HttpLink, InMemoryCache } from '@apollo/client';
import { POST_QUERY, POSTS_QUERY, ARCHIVE_MORE_QUERY, SEARCH_QUERY } from './queries/post.js';
import { BLOCK_MAPPING } from '../constants/blocks.js';
import { TERM_QUERY } from './queries/term.js';

var apolloClient;

// Runs any graphql queries that components may or may
// not have and adds the data as a blockData prop.
var _getBlocksData = function getBlocksData(blocks) {
  return new Promise(function ($return, $error) {
    return $return(Promise.all(blocks.map(function (block, index) {
      return new Promise(function ($return, $error) {
        var _BLOCK_MAPPING$block$;
        if (BLOCK_MAPPING[block.blockName] && (_BLOCK_MAPPING$block$ = BLOCK_MAPPING[block.blockName]) !== null && _BLOCK_MAPPING$block$ !== void 0 && _BLOCK_MAPPING$block$.getBlockData) {
          return Promise.resolve(_getBlocksData(block.innerBlocks)).then(function ($await_2) {
            try {
              return Promise.resolve(BLOCK_MAPPING[block.blockName].getBlockData(Object.assign({
                index: index
              }, block, {
                innerBlocks: $await_2
              }))).then(function ($await_3) {
                try {
                  return $return(Object.assign({
                    index: index
                  }, block, {
                    blockData: $await_3
                  }));
                } catch ($boundEx) {
                  return $error($boundEx);
                }
              }, $error);
            } catch ($boundEx) {
              return $error($boundEx);
            }
          }, $error);
        }
        return Promise.resolve(_getBlocksData(block.innerBlocks)).then(function ($await_4) {
          try {
            return $return(Object.assign({
              index: index
            }, block, {
              innerBlocks: $await_4
            }));
          } catch ($boundEx) {
            return $error($boundEx);
          }
        }, $error);
      });
    })));
  });
};
function createApolloClient() {
  var WP_GRAPHQL = "https://zg-graph-prod.int.zgcp-federated-prod-k8s.zg-int.net/graphql/" || '';
  return new ApolloClient({
    ssrMode: typeof window === 'undefined',
    link: new HttpLink({
      uri: WP_GRAPHQL,
      // Server URL (must be absolute)
      credentials: 'same-origin',
      // Additional fetch() options like `credentials` or `headers`
      headers: {
        'x-caller-id': 'cms-blocks'
      }
    }),
    cache: new InMemoryCache()
  });
}

// Initializes apollo
function initializeApollo() {
  // eslint-disable-next-line no-underscore-dangle
  var _apolloClient = apolloClient !== null && apolloClient !== void 0 ? apolloClient : createApolloClient();

  // Create the Apollo Client once in the client
  if (!apolloClient) {
    apolloClient = _apolloClient;
  }
  return _apolloClient;
}

// Makes the request to graphql
function getGraphQLData(_ref) {
  return new Promise(function ($return, $error) {
    var _response$errors, query, variables, _ref$errorPolicy, errorPolicy, fetchPolicy, response, _response$errors$0$ex, _response$errors$0$ex2;
    query = _ref.query, variables = _ref.variables, _ref$errorPolicy = _ref.errorPolicy, errorPolicy = _ref$errorPolicy === void 0 ? 'all' : _ref$errorPolicy, fetchPolicy = _ref.fetchPolicy;
    return Promise.resolve(apolloClient.query({
      query: query,
      variables: variables,
      errorPolicy: errorPolicy,
      fetchPolicy: fetchPolicy
    })).then(function ($await_5) {
      try {
        response = $await_5;
        // Check for errors
        if ((response === null || response === void 0 || (_response$errors = response.errors) === null || _response$errors === void 0 ? void 0 : _response$errors.length) > 0) {
          // Check if error is a 404 if so we want to return what we have in data.
          if (((_response$errors$0$ex = response.errors[0].extensions) === null || _response$errors$0$ex === void 0 ? void 0 : _response$errors$0$ex.code) === 'INTERNAL_SERVER_ERROR' && ((_response$errors$0$ex2 = response.errors[0].extensions) === null || _response$errors$0$ex2 === void 0 || (_response$errors$0$ex2 = _response$errors$0$ex2.response) === null || _response$errors$0$ex2 === void 0 ? void 0 : _response$errors$0$ex2.status) === 404) {
            return $return((response === null || response === void 0 ? void 0 : response.data) || {});
          }

          // Everything else we want to throw an error.
          console.error('Unable to fetch data from zg graph', response.errors, variables);
          return $error(new Error('Unable to fetch data from zg graph'));
        }

        // Check if data is not set something went wrong
        if (!(response !== null && response !== void 0 && response.data)) {
          console.error('Unable to fetch data from zg graph', variables);
          return $error(new Error('Unable to fetch data from zg graph'));
        }
        return $return(response.data);
      } catch ($boundEx) {
        return $error($boundEx);
      }
    }, $error);
  });
}
function getPost(options) {
  return new Promise(function ($return, $error) {
    var _options$variables, _options$variables$si, site, id, idType, _options$variables$po, postType, _options$variables$wi, withRevisions, _options$variables$wi2, withSettings, _await$getGraphQLData, cmsPost, cmsSettings;
    _options$variables = options.variables, _options$variables$si = _options$variables.site, site = _options$variables$si === void 0 ? 'ROOT' : _options$variables$si, id = _options$variables.id, idType = _options$variables.idType, _options$variables$po = _options$variables.postType, postType = _options$variables$po === void 0 ? 'PAGE' : _options$variables$po, _options$variables$wi = _options$variables.withRevisions, withRevisions = _options$variables$wi === void 0 ? false : _options$variables$wi, _options$variables$wi2 = _options$variables.withSettings, withSettings = _options$variables$wi2 === void 0 ? false : _options$variables$wi2;
    return Promise.resolve(getGraphQLData(Object.assign({}, options, {
      query: POST_QUERY,
      variables: {
        site: site,
        id: id,
        idType: idType,
        postType: postType,
        withRevisions: withRevisions,
        withSettings: withSettings
      }
    }))).then(function ($await_6) {
      try {
        _await$getGraphQLData = $await_6, cmsPost = _await$getGraphQLData.cmsPost, cmsSettings = _await$getGraphQLData.cmsSettings;
        return $return({
          post: cmsPost,
          settings: cmsSettings
        });
      } catch ($boundEx) {
        return $error($boundEx);
      }
    }, $error);
  });
}
function getPosts(options) {
  return new Promise(function ($return, $error) {
    var _options$variables2, _options$variables2$s, site, _options$variables2$p, postType, perPage, page, orderby, order, search, taxonomy, _options$variables2$w, withRevisions, _await$getGraphQLData2, cmsPosts;
    _options$variables2 = options.variables, _options$variables2$s = _options$variables2.site, site = _options$variables2$s === void 0 ? 'ROOT' : _options$variables2$s, _options$variables2$p = _options$variables2.postType, postType = _options$variables2$p === void 0 ? 'POST' : _options$variables2$p, perPage = _options$variables2.perPage, page = _options$variables2.page, orderby = _options$variables2.orderby, order = _options$variables2.order, search = _options$variables2.search, taxonomy = _options$variables2.taxonomy, _options$variables2$w = _options$variables2.withRevisions, withRevisions = _options$variables2$w === void 0 ? false : _options$variables2$w;
    return Promise.resolve(getGraphQLData(Object.assign({}, options, {
      variables: {
        site: site,
        postType: postType,
        perPage: perPage,
        page: page,
        orderby: orderby,
        order: order,
        search: search,
        taxonomy: taxonomy,
        withRevisions: withRevisions
      },
      query: POSTS_QUERY
    }))).then(function ($await_7) {
      try {
        _await$getGraphQLData2 = $await_7, cmsPosts = _await$getGraphQLData2.cmsPosts;
        return $return(cmsPosts);
      } catch ($boundEx) {
        return $error($boundEx);
      }
    }, $error);
  });
}
function getArchiveMore(options) {
  return new Promise(function ($return, $error) {
    var _options$variables3, _options$variables3$s, site, perPage, page, orderby, order, search, taxonomies, _await$getGraphQLData3, cmsPosts;
    _options$variables3 = options.variables, _options$variables3$s = _options$variables3.site, site = _options$variables3$s === void 0 ? 'ROOT' : _options$variables3$s, perPage = _options$variables3.perPage, page = _options$variables3.page, orderby = _options$variables3.orderby, order = _options$variables3.order, search = _options$variables3.search, taxonomies = _options$variables3.taxonomies;
    return Promise.resolve(getGraphQLData(Object.assign({}, options, {
      variables: {
        site: site,
        perPage: perPage,
        page: page,
        orderby: orderby,
        order: order,
        search: search,
        taxonomies: taxonomies
      },
      query: ARCHIVE_MORE_QUERY
    }))).then(function ($await_8) {
      try {
        _await$getGraphQLData3 = $await_8, cmsPosts = _await$getGraphQLData3.cmsPosts;
        return $return(cmsPosts);
      } catch ($boundEx) {
        return $error($boundEx);
      }
    }, $error);
  });
}
function getSearchPosts(options) {
  return new Promise(function ($return, $error) {
    var _options$variables4, _options$variables4$s, site, perPage, page, search, _await$getGraphQLData4, cmsPosts, cmsSettings, cmsSearchHead;
    _options$variables4 = options.variables, _options$variables4$s = _options$variables4.site, site = _options$variables4$s === void 0 ? 'ROOT' : _options$variables4$s, perPage = _options$variables4.perPage, page = _options$variables4.page, search = _options$variables4.search;
    return Promise.resolve(getGraphQLData(Object.assign({}, options, {
      variables: {
        site: site,
        perPage: perPage,
        page: page,
        search: search
      },
      query: SEARCH_QUERY
    }))).then(function ($await_9) {
      try {
        _await$getGraphQLData4 = $await_9, cmsPosts = _await$getGraphQLData4.cmsPosts, cmsSettings = _await$getGraphQLData4.cmsSettings, cmsSearchHead = _await$getGraphQLData4.cmsSearchHead;
        return $return({
          posts: cmsPosts,
          settings: cmsSettings,
          head: cmsSearchHead === null || cmsSearchHead === void 0 ? void 0 : cmsSearchHead.head
        });
      } catch ($boundEx) {
        return $error($boundEx);
      }
    }, $error);
  });
}
function getTerm(options) {
  return new Promise(function ($return, $error) {
    var _options$variables5, _options$variables5$s, site, id, _options$variables5$i, idType, _options$variables5$t, taxonomy, _options$variables5$t2, termPostsType, _options$variables5$t3, termPostsPage, _options$variables5$t4, termPostsPerPage, _options$variables5$w, withSettings, _await$getGraphQLData5, cmsTerm, cmsSettings;
    _options$variables5 = options.variables, _options$variables5$s = _options$variables5.site, site = _options$variables5$s === void 0 ? 'ROOT' : _options$variables5$s, id = _options$variables5.id, _options$variables5$i = _options$variables5.idType, idType = _options$variables5$i === void 0 ? 'SLUG' : _options$variables5$i, _options$variables5$t = _options$variables5.taxonomy, taxonomy = _options$variables5$t === void 0 ? 'CATEGORY' : _options$variables5$t, _options$variables5$t2 = _options$variables5.termPostsType, termPostsType = _options$variables5$t2 === void 0 ? 'POST' : _options$variables5$t2, _options$variables5$t3 = _options$variables5.termPostsPage, termPostsPage = _options$variables5$t3 === void 0 ? '1' : _options$variables5$t3, _options$variables5$t4 = _options$variables5.termPostsPerPage, termPostsPerPage = _options$variables5$t4 === void 0 ? '10' : _options$variables5$t4, _options$variables5$w = _options$variables5.withSettings, withSettings = _options$variables5$w === void 0 ? false : _options$variables5$w;
    return Promise.resolve(getGraphQLData(Object.assign({}, options, {
      query: TERM_QUERY,
      variables: {
        site: site,
        id: id,
        idType: idType,
        taxonomy: taxonomy,
        termPostsType: termPostsType,
        termPostsPage: termPostsPage,
        termPostsPerPage: termPostsPerPage,
        withSettings: withSettings
      }
    }))).then(function ($await_10) {
      try {
        _await$getGraphQLData5 = $await_10, cmsTerm = _await$getGraphQLData5.cmsTerm, cmsSettings = _await$getGraphQLData5.cmsSettings;
        return $return({
          term: cmsTerm,
          settings: cmsSettings
        });
      } catch ($boundEx) {
        return $error($boundEx);
      }
    }, $error);
  });
}

export { createApolloClient, getArchiveMore, _getBlocksData as getBlocksData, getGraphQLData, getPost, getPosts, getSearchPosts, getTerm, initializeApollo };
