import API from '@zillow/mortgage-api-client';
import { createMax } from '../../utils/mortgagehelpers/mortgage-helpers.js';
import { DEFAULT_PARTNER_ID } from '../../utils/mortgagehelpers/constants.js';

var fetchRatesArticle = function fetchRatesArticle(input) {
  return new Promise(function ($return, $error) {
    var inputs;
    inputs = Object.assign({
      partnerId: API.partnerId ? API.partnerId : DEFAULT_PARTNER_ID
    }, input);
    createMax();
    if (input.stateAbbreviation === 'US') {
      delete inputs.stateAbbreviation;
    }
    var $Try_1_Catch = function (e) {
      try {
        console.error(e);
        return $return({
          content: '',
          hasApiError: true
        });
      } catch ($boundEx) {
        return $error($boundEx);
      }
    };
    try {
      return Promise.resolve(API.MAX.GET({
        action: 'getRatesArticle',
        input: inputs
      })).then($return, $Try_1_Catch);
    } catch (e) {
      $Try_1_Catch(e);
    }
  });
};

export { fetchRatesArticle };
