import { slicedToArray as _slicedToArray } from '../../_virtual/_rollupPluginBabelHelpers.js';
import { dollar, fixedPercent, roundPercent } from '@zillow/formatters';
import API from '@zillow/mortgage-api-client';
import { PAGE_FLAVOR_LOAN_TYPE_MAP, LOAN_TYPES, DEFAULT_PARTNER_ID } from '../../utils/mortgagehelpers/constants.js';
import MortgagePageClassification from '../../utils/mortgagehelpers/MortgagePageClassification.js';
import { createMax } from '../../utils/mortgagehelpers/mortgage-helpers.js';

var _PROGRAM_NAMES;

/**
 * An enumeration of branding constants
 */
var BRANDING = {
  ZGMI_INC: 'Zillow Group Marketplace, Inc.',
  ZGMI_FULL_NAME: 'Zillow Group Marketplace, Inc. ("ZGMI")',
  ZGMI_FRIENDLY_NAME: 'Zillow Group Marketplace',
  ZGMI_NMLS_ID: 'NMLS #1303160'
};

/**
 * Formats a number value as a dollar amount.
 *
 * @method dollar
 * @param {Number} value The value to be formatted.
 * @param {Boolean} [cents=false] Whether to include cents instead of rounding to the nearest dollar.
 * @param {Boolean} [parentheses=false] Whether to format negative amounts in parentheses.
 */
function formatIntoDollars(value, cents, parentheses) {
  var s = dollar(value, !!cents);
  if (parentheses && s[0] === '-') {
    s = '(' + s.substring(1) + ')';
  }
  return s;
}

/**
 * Formats a number value as a percent.
 *
 * @method percent
 * @param {Number} value The value to be formatted.
 * @param {Boolean} [fixed=false] Whether to format with fixed decimal point instead of rounding.
 * @param {Number} [decimalPlaces] How many decimal places to limit the result to.
 */
function formatIntoPercent(value, fixed, decimalPlaces) {
  return fixed ? fixedPercent(value, decimalPlaces) : roundPercent(value, decimalPlaces);
}
var PROGRAM_KEYS = {
  Fixed30Year: 'Fixed30Year',
  Fixed20Year: 'Fixed20Year',
  Fixed15Year: 'Fixed15Year',
  Fixed10Year: 'Fixed10Year',
  ARM7: 'ARM7',
  ARM5: 'ARM5',
  ARM3: 'ARM3'
};
var PROGRAM_NAMES = (_PROGRAM_NAMES = {}, _PROGRAM_NAMES[PROGRAM_KEYS.Fixed30Year] = '30-Year Fixed Rate', _PROGRAM_NAMES[PROGRAM_KEYS.Fixed20Year] = '20-Year Fixed Rate', _PROGRAM_NAMES[PROGRAM_KEYS.Fixed15Year] = '15-Year Fixed Rate', _PROGRAM_NAMES[PROGRAM_KEYS.Fixed10Year] = '10-Year Fixed Rate', _PROGRAM_NAMES[PROGRAM_KEYS.ARM7] = '7-year ARM', _PROGRAM_NAMES[PROGRAM_KEYS.ARM5] = '5-year ARM', _PROGRAM_NAMES[PROGRAM_KEYS.ARM3] = '3-year ARM', _PROGRAM_NAMES);
var Fixed30Year = PROGRAM_KEYS.Fixed30Year,
  Fixed20Year = PROGRAM_KEYS.Fixed20Year,
  Fixed15Year = PROGRAM_KEYS.Fixed15Year,
  Fixed10Year = PROGRAM_KEYS.Fixed10Year,
  ARM7 = PROGRAM_KEYS.ARM7,
  ARM5 = PROGRAM_KEYS.ARM5,
  ARM3 = PROGRAM_KEYS.ARM3;
var DEFAULT_LOAN_PROGRAMS = [Fixed30Year, Fixed15Year, ARM5];
var CONFORMING_LOAN_PROGRAMS = [Fixed30Year, Fixed20Year, Fixed15Year, Fixed10Year, ARM7, ARM5, ARM3];
var getLegalDisclosureText = function getLegalDisclosureText(todayPayment, today, name, isRefinance) {
  var loanAmount = formatIntoDollars(todayPayment.loanAmount);
  var monthlyPayment = formatIntoDollars(todayPayment.monthlyPayment);
  var apr = formatIntoPercent(today.apr, true, 2);
  if (isRefinance) {
    var homeValue = formatIntoDollars(todayPayment.loanAmount / (todayPayment.loanToValue / 100));
    return "A " + name + " loan of " + loanAmount + " at " + apr + " APR with an estimated home value of " + homeValue + " will have a monthly payment of " + monthlyPayment + ".";
  } else {
    var downPayment = formatIntoDollars(todayPayment.downPayment);
    return "A " + name + " loan of " + loanAmount + " at " + apr + " APR with a down payment of " + downPayment + " will have a monthly payment of " + monthlyPayment + ".";
  }
};
var getNationalConformingLoanLimits = function getNationalConformingLoanLimits() {
  return new Promise(function ($return, $error) {
    var limits;
    createMax();
    var $Try_1_Catch = function (e) {
      try {
        return $return({});
      } catch ($boundEx) {
        return $error($boundEx);
      }
    };
    try {
      return Promise.resolve(API.MAX.GET({
        action: 'getNationalConformingLoanLimits',
        input: {
          partnerId: API.partnerId ? API.partnerId : DEFAULT_PARTNER_ID
        }
      })).then(function ($await_5) {
        try {
          limits = $await_5;
          return $return(limits);
        } catch ($boundEx) {
          return $Try_1_Catch($boundEx);
        }
      }, $Try_1_Catch);
    } catch (e) {
      $Try_1_Catch();
    }
  });
};
var getRateTablesApi = function getRateTablesApi(_ref) {
  return new Promise(function ($return, $error) {
    var queriesOverride, loanPrograms, stateAbbreviation, refinance, loanType, loanAmountBucket, loanToValueBucket, creditScoreBucket, queries;
    queriesOverride = _ref.queriesOverride, loanPrograms = _ref.loanPrograms, stateAbbreviation = _ref.stateAbbreviation, refinance = _ref.refinance, loanType = _ref.loanType, loanAmountBucket = _ref.loanAmountBucket, loanToValueBucket = _ref.loanToValueBucket, creditScoreBucket = _ref.creditScoreBucket;
    queries = queriesOverride;
    createMax();
    if (!queries) {
      queries = loanPrograms.reduce(function (acc, loanProgram) {
        var _Object$assign;
        return Object.assign({}, acc, (_Object$assign = {}, _Object$assign[loanProgram] = {
          program: loanProgram,
          stateAbbreviation: stateAbbreviation,
          refinance: refinance,
          loanType: loanType,
          loanAmountBucket: loanAmountBucket,
          loanToValueBucket: loanToValueBucket,
          creditScoreBucket: creditScoreBucket
        }, _Object$assign));
      }, {});
    }
    return Promise.resolve(API.MAX.GET({
      action: 'getRateTables',
      input: {
        partnerId: API.partnerId ? API.partnerId : DEFAULT_PARTNER_ID,
        queries: queries
      }
    }).then(function (response) {
      return Object.entries(response.rates).reduce(function (acc, _ref2) {
        var _PROGRAM_NAMES$key, _Object$assign2;
        var _ref3 = _slicedToArray(_ref2, 2),
          key = _ref3[0],
          value = _ref3[1];
        return Object.assign({}, acc, (_Object$assign2 = {}, _Object$assign2[key] = Object.assign({
          id: key
        }, value, {
          name: (_PROGRAM_NAMES$key = PROGRAM_NAMES[key]) !== null && _PROGRAM_NAMES$key !== void 0 ? _PROGRAM_NAMES$key : key // required to display program name
        }), _Object$assign2));
      }, {});
    })).then($return, $error);
  });
};
var getMortgageRates = function getMortgageRates(pathname) {
  return new Promise(function ($return, $error) {
    var _PAGE_FLAVOR_LOAN_TYP, classification, stateAbbreviation, flavor, refinance, city, loanType, isRefinance, data, inputs, getData, result;
    classification = new MortgagePageClassification(pathname ? pathname : '/mortgage-rates/');
    stateAbbreviation = classification.stateAbbreviation, flavor = classification.flavor, refinance = classification.refinance, city = classification.city;
    loanType = (_PAGE_FLAVOR_LOAN_TYP = PAGE_FLAVOR_LOAN_TYPE_MAP[flavor]) !== null && _PAGE_FLAVOR_LOAN_TYP !== void 0 ? _PAGE_FLAVOR_LOAN_TYP : LOAN_TYPES.Conventional;
    isRefinance = refinance;
    data = {};
    inputs = [{
      queriesOverride: null,
      refinance: isRefinance,
      loanPrograms: CONFORMING_LOAN_PROGRAMS
    }, {
      queriesOverride: governmentQueries(isRefinance, stateAbbreviation),
      refinance: false,
      loanPrograms: DEFAULT_LOAN_PROGRAMS
    }, {
      queriesOverride: jumboQueries(isRefinance, stateAbbreviation, city),
      refinance: false,
      loanPrograms: DEFAULT_LOAN_PROGRAMS
    }];
    var $Try_2_Catch = function (error) {
      try {
        console.error('Error fetching data:', error);
        return $return(data);
      } catch ($boundEx) {
        return $error($boundEx);
      }
    };
    try {
      getData = function getData() {
        return new Promise(function ($return, $error) {
          var i, response;
          i = 0;
          var $Loop_3_trampoline;
          function $Loop_3_step() {
            i++;
            return $Loop_3;
          }
          function $Loop_3() {
            if (i < inputs.length) {
              return Promise.resolve(getRateTablesApi(Object.assign({}, DEFAULT_RATE_API_PARAMS, {
                loanPrograms: inputs[i].loanPrograms,
                queriesOverride: inputs[i].queriesOverride,
                stateAbbreviation: stateAbbreviation,
                refinance: inputs[i].refinance,
                loanType: loanType
              }))).then(function ($await_7) {
                try {
                  response = $await_7;
                  data = Object.assign({}, data, response);
                  return $Loop_3_step;
                } catch ($boundEx) {
                  return $error($boundEx);
                }
              }, $error);
            } else return [1];
          }
          return ($Loop_3_trampoline = function (q) {
            while (q) {
              if (q.then) return void q.then($Loop_3_trampoline, $error);
              try {
                if (q.pop) {
                  if (q.length) return q.pop() ? $Loop_3_exit.call(this) : q;else q = $Loop_3_step;
                } else q = q.call(this);
              } catch (_exception) {
                return $error(_exception);
              }
            }
          }.bind(this))($Loop_3);
          function $Loop_3_exit() {
            return $return(data);
          }
        });
      };
      return Promise.resolve(getData()).then(function ($await_8) {
        try {
          result = $await_8;
          return $return(result);
        } catch ($boundEx) {
          return $Try_2_Catch($boundEx);
        }
      }, $Try_2_Catch);
    } catch (error) {
      $Try_2_Catch(error);
    }
  });
};
var FHA = LOAN_TYPES.FHA,
  VA = LOAN_TYPES.VA;
var LOAN_TO_VALUE_BUCKET = {
  Normal: 'Normal',
  VeryHigh: 'VeryHigh'
};
var CREDIT_SCORE_BUCKET = {
  High: 'High',
  VeryHigh: 'VeryHigh'
};
var DEFAULT_RATE_API_PARAMS = {
  loanType: LOAN_TYPES.Conventional,
  loanAmountBucket: 'Conforming',
  loanToValueBucket: LOAN_TO_VALUE_BUCKET.Normal,
  creditScoreBucket: CREDIT_SCORE_BUCKET.VeryHigh
};
var governmentQueries = function governmentQueries(refinance, stateAbbreviation) {
  var common = {
    refinance: !!refinance,
    stateAbbreviation: stateAbbreviation,
    loanToValueBucket: LOAN_TO_VALUE_BUCKET.VeryHigh,
    creditScoreBucket: CREDIT_SCORE_BUCKET.High
  };
  return {
    '30-Year Fixed Rate FHA': Object.assign({}, common, {
      program: PROGRAM_KEYS.Fixed30Year,
      loanType: FHA
    }),
    '30-Year Fixed Rate VA': Object.assign({}, common, {
      program: PROGRAM_KEYS.Fixed30Year,
      loanType: VA
    }),
    '15-Year Fixed Rate FHA': Object.assign({}, common, {
      program: PROGRAM_KEYS.Fixed15Year,
      loanType: FHA
    }),
    '15-Year Fixed Rate VA': Object.assign({}, common, {
      program: PROGRAM_KEYS.Fixed15Year,
      loanType: VA
    })
  };
};
var LOAN_AMOUNT_BUCKETS = {
  Jumbo: 'Jumbo',
  Conforming: 'Conforming'
};
var Jumbo = LOAN_AMOUNT_BUCKETS.Jumbo;
var jumboQueries = function jumboQueries(refinance, stateAbbreviation, cityName) {
  var common = {
    loanAmountBucket: Jumbo,
    refinance: !!refinance,
    stateAbbreviation: stateAbbreviation,
    paymentSummaryOptions: {
      stateAbbreviation: stateAbbreviation,
      cityName: cityName
    }
  };
  return {
    '30-Year Fixed Rate Jumbo': Object.assign({}, common, {
      program: Fixed30Year
    }),
    '15-Year Fixed Rate Jumbo': Object.assign({}, common, {
      program: Fixed15Year
    }),
    '7-year ARM Jumbo': Object.assign({}, common, {
      program: ARM7
    }),
    '5-year ARM Jumbo': Object.assign({}, common, {
      program: ARM5
    }),
    '3-year ARM Jumbo': Object.assign({}, common, {
      program: ARM3
    })
  };
};

export { BRANDING, CREDIT_SCORE_BUCKET, DEFAULT_RATE_API_PARAMS, Jumbo, LOAN_AMOUNT_BUCKETS, LOAN_TO_VALUE_BUCKET, PROGRAM_KEYS, PROGRAM_NAMES, formatIntoDollars, formatIntoPercent, getLegalDisclosureText, getMortgageRates, getNationalConformingLoanLimits, getRateTablesApi, governmentQueries, jumboQueries };
