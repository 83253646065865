import { CloseButton } from '@zillow/constellation';
import { yieldCallback } from '@zillow/yield-callback';
import React, { useState } from 'react';

import { StyledBanner } from './PreviewModeAlert.styles';

/**
 * A component that displays a warning banner when the user is in preview mode.
 */
const PreviewModeAlert: React.FC = () => {
    const [isOpen, setIsOpen] = useState(true);
    return (
        <StyledBanner
            appearance="warning"
            body="You are viewing a preview of this content."
            isOpen={isOpen}
            closeButton={
                <CloseButton onClick={yieldCallback(() => setIsOpen(false))} />
            }
        />
    );
};

export default PreviewModeAlert;
