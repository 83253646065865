import _debounce from 'lodash/debounce';
import { event } from '@zillow/universal-analytics';

// Handle field change, debounced by 3 seconds.
var sendFieldInteractionEvent = _debounce(function (label, block_type, block_id) {
  var cmsInfo = {
    block_type_cd: block_type,
    block_id: block_id,
    block_displayed_txt: label
  };
  sendInteractionEvent(cmsInfo, block_type);
}, 3000);
var handleFieldClickEvent = _debounce(function (label, block_type, block_id) {
  var cmsInfo = {
    block_type_cd: block_type,
    block_id: block_id,
    block_displayed_txt: label
  };
  sendInteractionClickEvent(cmsInfo);
}, 3000);
var sendInteractionEvent = function sendInteractionEvent(cmsInfo, block_type) {
  var newLaneData = {
    envelope: {
      event_type_id: '2056',
      event_type_version_id: '2',
      event_template_id: '126',
      event_template_version_id: '1'
    },
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'cms',
      trigger_source_nm: block_type == 'form_field' ? 'cms_form' : 'cms_toggle',
      trigger_object_nm: 'no_trigger_object'
    },
    semantic: {
      semantic_event_nm: 'cms_engagement'
    },
    cms_info: cmsInfo
  };
  event(newLaneData);
};
var sendInteractionClickEvent = function sendInteractionClickEvent(cmsInfo) {
  var newLaneData = {
    envelope: {
      event_type_id: '2048',
      event_type_version_id: '3',
      event_template_id: '126',
      event_template_version_id: '1'
    },
    clickstream_trigger: {
      trigger_type_nm: 'interaction',
      trigger_location_nm: 'cms',
      trigger_source_nm: 'cms_toggle',
      trigger_object_nm: 'no_trigger_object'
    },
    semantic: {
      semantic_event_nm: 'cms_engagement'
    },
    cms_info: cmsInfo
  };
  event(newLaneData);
};
function legalPopoverInteractionEventBlock(block_id) {
  var legalPopoverInteractionEventCmsInfo = {
    block_type_cd: 'modal_dialog',
    block_id: block_id,
    block_displayed_txt: 'Calculator disclaimer'
  };
  return legalPopoverInteractionEventCmsInfo;
}

export { handleFieldClickEvent, legalPopoverInteractionEventBlock, sendFieldInteractionEvent };
